<div class="content content-form d-flex flex-column flex-column-fluid">
    <ng-container>
        <div class="margin-bottom-50"></div>
    <div >
        <div class="row">
            <div class="col-md-12 text-center">
                <h1 class="font-green-ythd">{{'ThongBaoTrangThai' | localize}}</h1>
                <p>{{'VuiLongLienHeDeBietThemChiTiet' | localize}}</p>
                <p>{{'Email' | localize}}: kyawtkyawtkhaing@mytel.com.mm</p>
                <p>{{'PhoneNumber' | localize}}: +958888.....</p>
            </div>
        </div>
    </div>
    </ng-container>
</div>


