import { Component, forwardRef, Input, OnInit, Output, EventEmitter, Provider } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const VALUE_ACCESSOR: Provider = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => CurrencyInput2Component),
	multi: true
};

@Component({
	selector: 'ord-currency-input2',
	template: `
    
      <nz-input-number
      [ngModel]="_value"
        class="w-100"
        nz-input
        [nzStep]="1"
        [nzMin]="0"
        (ngModelChange)="changeValue($event)"
        class="ord-dynamic-input"
        [disabled]="disabled"
        style="width: 100%;"
        [nzPlaceHolder]="placeholder"
        ></nz-input-number>`,
	providers: [VALUE_ACCESSOR],
})
export class CurrencyInput2Component implements OnInit, ControlValueAccessor {

	@Input() _value = null;

	@Input() placeholder = '';

	@Input() disabled = null;

	@Output() _valueChange = new EventEmitter();

	@Input()

    get value() {
        return this._value;
    }

    set value(v: any) {
        this._value = v;
    }

    currencySign = ' ' + abp.setting.get("App.MySMSSetting.CurrencyKey") + ' '

	private onChange: Function = (v: any) => {
    };

    private onTouched: Function = () => {

    };

	ngOnInit(): void {

	}


	changeValue($event) {
        var format = $event;
        format = `${format}`.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");

        var indexOf = format.indexOf(".");
        if(indexOf > 0 ){
            var integer = format.slice(0 ,indexOf+1)
            var decimal = format.slice(indexOf + 1 ,format.length).split(',').join('');
            format = integer +  decimal ;
        }
		this._value = format;
        this.onChange($event);
		this._valueChange.emit($event);
	}

    formatterPercent = (value: number): string => `${value ?value : 0} ${" " + this.currencySign}`;

	writeValue(value: boolean): void {
        this._value = value;
    }

	registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: Function): void {
        this.onTouched = fn;
    }

}
