import { Component, Injector, OnInit } from '@angular/core';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
  selector: 'check-active',
  templateUrl: './check-active.component.html'
})
export class CheckActiveComponent extends AppComponentBase implements  OnInit {

  constructor(injector: Injector,
    private _authService: AppAuthService
    )
  {
  super(injector);

  }

  ngOnInit(): void {

  }


  logout(): void {
  this._authService.logout();
  }

}
