import { ZeroRefreshTokenService } from '@account/auth/zero-refresh-token.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AbpHttpConfigurationService, AbpHttpInterceptor, RefreshTokenService } from 'abp-ng2-module';
import * as ApiServiceProxies from './service-proxies';
import { ZeroTemplateHttpConfigurationService } from './zero-template-http-configuration.service';

@NgModule({
    providers: [
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.CommonServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,

        //danh mục
        ApiServiceProxies.DanhMucHuyenServiceProxy,
        ApiServiceProxies.DanhMucTinhServiceProxy,
        ApiServiceProxies.HostReportMessageHistoryServiceProxy,
        ApiServiceProxies.DanhMucXaServiceProxy,
        ApiServiceProxies.TaiKhoanNguoiDungServiceProxy,
        ApiServiceProxies.ComboBaseServiceProxy,
        ApiServiceProxies.CommonServiceProxy,
        ApiServiceProxies.DanhMucQuocTichServiceProxy,
        ApiServiceProxies.TaiLieuHuongDanServiceProxy,
        ApiServiceProxies.LstDictionaryServiceProxy,
        ApiServiceProxies.LstDictionaryTypeServiceProxy,

        ApiServiceProxies.QuanLyNapTienServiceProxy,
        ApiServiceProxies.CustomerReportMessageHistoryServiceProxy,
        ApiServiceProxies.DanhMucDauSoServiceProxy,
        ApiServiceProxies.CustomerManagementServiceProxy,
        ApiServiceProxies.ContractServiceProxy,
        ApiServiceProxies.ProductPriceMytelServiceProxy,
        ApiServiceProxies.DanhMucTuHanCheServiceProxy,
        ApiServiceProxies.DanhMucNganHangServiceProxy,
        ApiServiceProxies.DanhMucNguoiHoTroServiceProxy,
        ApiServiceProxies.BaoCaoTongHopServiceProxy,
        ApiServiceProxies.BaoCaoHoanTraServiceProxy,
        ApiServiceProxies.BaoCaoDoiSoatServiceProxy,
        ApiServiceProxies.BaoCaoKhachHangChiTietServiceProxy,
        ApiServiceProxies.TraCuuThanhToanPhiBanHangServiceProxy,
        ApiServiceProxies.DanhMucBrandNameNoRegisterServiceProxy,
        ApiServiceProxies.BaoCaoHoaHongDaiLyServiceProxy,
        ApiServiceProxies.BaoCaoHoaHongCongTacVienServiceProxy,
        ApiServiceProxies.BaoCaoTongHopTinNhanServiceProxy,
        ApiServiceProxies.BaoCaoTomTatLuuLuongTinNhanServiceProxy,
        ApiServiceProxies.HostMonthlyReportServiceProxy,
        //dùng chung
        ApiServiceProxies.UtilityServiceProxy,
        ApiServiceProxies.FileUploadServiceProxy,

        // Đăng ký
        ApiServiceProxies.DangKyServiceProxy,

        // Brand Name
        ApiServiceProxies.BrandNameRegistrationServiceProxy,
        ApiServiceProxies.BrandNameManageServiceProxy,
        ApiServiceProxies.HoanTienServiceProxy,

        //Liên hệ
        ApiServiceProxies.LienHeServiceProxy,
        ApiServiceProxies.NhomLienHeServiceProxy,

        //Quản lý số dư
        ApiServiceProxies.QuanLySoDuServiceProxy,
        ApiServiceProxies.MuaTinNhanServiceProxy,
        ApiServiceProxies.GoiTinNhanSuDungServiceProxy,

        //Tin Nhắn
        ApiServiceProxies.TemplateServiceProxy,
        ApiServiceProxies.NhanTinMotNoiDungServiceProxy,
        ApiServiceProxies.ChienDichGuiTinServiceProxy,

        // Tin Nhắn
        ApiServiceProxies.TinNhanChucMungSinhNhatServiceProxy,
        ApiServiceProxies.NotificationManageServiceProxy,
        ApiServiceProxies.CommonMessageServiceProxy,

        //agency
        ApiServiceProxies.CollaboratorServiceProxy,

        ApiServiceProxies.AgencyManagementServiceProxy,
        ApiServiceProxies.TinNhanKhongHopLeServiceProxy,
        ApiServiceProxies.PurchasedOrderServiceProxy,
        ApiServiceProxies.CustomerRegistrationServiceProxy,
        //ApiServiceProxies.DashboardServiceProxy,
        { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
        { provide: AbpHttpConfigurationService, useClass: ZeroTemplateHttpConfigurationService },
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
    ],
})
export class ServiceProxyModule {}
