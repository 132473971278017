import { Component, OnInit, Input, Provider, forwardRef, OnDestroy, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef, } from '@angular/core';
import { fromEvent, Observable, of, Subject, Subscription } from 'rxjs';
import { ControlValueAccessor, Form, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { NzDatePickerComponent } from '@node_modules/ng-zorro-antd/date-picker';
import { DateTime } from 'luxon';
//import * as moment from 'moment';
import moment from 'moment';
import * as $ from 'jquery';
import { differenceInCalendarDays } from 'date-fns';

const VALUE_ACCESSOR: Provider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OraDatePickerV2Component),
    multi: true,
};

@Component({
    selector: 'ora-date-picker-v2',
    template: `
        <div class="main-ora-date" (mouseenter)="mouseEnterMain()" (mouseleave)="mouseLeaveMain()">
            <nz-date-picker class="ora-date" #refDate style="width:100%" [nzPlaceHolder]="placeHolder"
                            [nzDisabledDate]="disabledDates"
                            nzDisabled="{{_isDisabled}}"
                            tabindex="-1"
                            [formControl]="control"
                            nzFormat="dd/MM/yyyy" ></nz-date-picker>
            <input #refInput class="ora-input-date {{(_isDisabled == true)?'ora-input-date-disabled':''}}" nz-input
                   [placeholder]="placeHolder"
                   [formControl]="_inputValue"
                   [textMask]="{mask: _mask}"
                   disabled="{{_isDisabled}}"
                   style="{{inputStyle}}"
                   (ngModelChange)="onChangeValue($event)"/>
            <i class="ora-close" *ngIf="isIcon" [hidden]="_isShowIconCalendar" (click)="onClearClick()" nz-icon nzType="close-circle"
               nzTheme="outline"></i>
            <i class="ora-calendar" #oraCalendar *ngIf="isIcon" [hidden]="!_isShowIconCalendar"  nz-icon
            nzType="calendar"
            nzTheme="outline"></i>
           <ng-template #suffixIcon>
            <i class="ora-calendar" *ngIf="isIcon" [hidden]="!_isShowIconCalendar"  nz-icon
                nzType="calendar"
                nzTheme="outline"></i>
           </ng-template>
        </div>
    `,
    styles: [`
        .main-ora-date {
            position: relative;
            width: 100%;
        }
        .main-ora-date .ant-picker.ant-picker-disabled{
            border: none;
        }
        .ora-date {
            border: 0;
        }
        .ora-input-date{
            background-color: #fff;
        }
        .ora-input-date {
            position: absolute;
            top: 0;
            left: 0
        }

        .ora-close {
            position: absolute;
            top: 7px;
            right: 5px;
            z-index: 1;
        }

        .ora-calendar {
            position: absolute;
            top: 7px;
            right: 5px;
            z-index: 1;
        }

        .ora-input-date-disabled{
            color: rgba(0, 0, 0, 0.25);
            background-color: #f5f5f5;
            cursor: not-allowed;
            opacity: 1;
        }
    `],
    providers: [VALUE_ACCESSOR],
})

export class OraDatePickerV2Component implements OnInit, ControlValueAccessor, AfterViewInit, OnDestroy {
    @ViewChild('refDate') refDate: NzDatePickerComponent;
    @ViewChild('refInput') refInput: ElementRef;
    @ViewChild('oraCalendar') oraCalendar: ElementRef;
    @Input() disabledDate?: (d: Date) => boolean;
    @Input() isIcon?: boolean = true;
    @Input() isDisabled?: boolean = false;
    @Input() placeHolder = 'dd/MM/yyyy';
    @Input() minDate?: Date;
    @Input() maxDate?: Date;
    @Input() inputStyle?: string = "";
    _mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
    $destroy: Subject<boolean> = new Subject<boolean>();
    isWriteValue = false;
    _isOnChange = false;
    _isShowIconCalendar = true;

    get value() {
        return this.control.value;
    }

    set value(v: any) {
        this.control.setValue(v);
    }

    _isDisabled = false;

    @Input()
    get disabled() {
        return this._isDisabled;
    }

    set disabled(v: boolean) {
        this._isDisabled = v;
    }

    @Input() control = new FormControl({ value: '', disabled: false });
    _inputValue: FormControl = new FormControl({ value: undefined, disabled: this._isDisabled }, Validators.required);


    private onChange = (v: any) => {
    };
    private onTouched = () => {
    };


    onChangeValue(event: any): void {
        this.onChange(event);
        // this.refDate.picker.hideOverlay();
        if (event == undefined || (event != undefined && event.length <= 10 && event.toString().indexOf('_') !== -1)) {

            this._isOnChange = true;
            return;
        } else {
            var checkValidDate = moment(event, 'DD/MM/YYYY', true).isValid();
            if (checkValidDate == false) {
                this._inputValue.setValue(undefined);
                //this.messages = 'Nhập ngày/tháng/năm';
                return;
            }
        }
    }

    onFocus(event: any): void {
        this.onTouched();
    }

    mouseLeaveMain() {
        this._isShowIconCalendar = true;
    }

    mouseEnterMain() {
        if (this._inputValue.value) {
            this._isShowIconCalendar = false;
        } else {
            this._isShowIconCalendar = true;
        }
    }

    constructor(private cd: ChangeDetectorRef) {

    }

    ngAfterViewInit(): void {
        fromEvent<any>(this.refInput.nativeElement, 'click')
            .pipe(
                debounceTime(400),
                takeUntil(this.$destroy),
            ).subscribe(() => {
                this.onInputClick();
            });

        if (this.isIcon == true) {
            fromEvent<any>(this.oraCalendar.nativeElement, 'click')
                .pipe(
                    debounceTime(400),
                    takeUntil(this.$destroy),
                ).subscribe(() => {
                    this.onInputClick();
                });
        }

        fromEvent<any>($('app-root'), 'click')
            .pipe(
                debounceTime(0),
                takeUntil(this.$destroy),
            ).subscribe(() => {
                this.refDate.picker.hideOverlay();
                this.refDate.nzOpen = false;
            });
        fromEvent<any>(document, 'keydown')
            .pipe(
                debounceTime(0),
                takeUntil(this.$destroy),
            ).subscribe((event) => {
                if (event.code == "Tab") {
                    this.refDate.picker.hideOverlay();
                    this.refDate.nzOpen = false;
                }
            });
        fromEvent<any>($('nz-modal-container'), 'click')
            .pipe(
                debounceTime(0),
                takeUntil(this.$destroy),
            ).subscribe(() => {
                this.refDate.picker.hideOverlay();
                this.refDate.nzOpen = false;
            });
    }

    onInputClick() {
        this.refDate.picker.showOverlay();
        this.refDate.nzOpen = true;
        setTimeout(() => {
            // this will make the execution after the above boolean has changed
            this.refInput.nativeElement.focus();
            fromEvent<any>($('.ant-picker-cell-selected, .ant-picker-today-btn'), 'click')
                .pipe(
                    debounceTime(0),
                    takeUntil(this.$destroy),
                ).subscribe(() => {
                    this.refDate.picker.hideOverlay();
                    this.refDate.nzOpen = false;
                });
        }, 0);
    }

    onClearClick() {
        this._inputValue.setValue(undefined);
        this._isShowIconCalendar = true;
    }

    ngOnDestroy(): void {
        this.$destroy.next(true);
        this.$destroy.unsubscribe();
    }

    ngOnInit(): void {
        this.control.valueChanges.pipe(takeUntil(this.$destroy), distinctUntilChanged((prev, curr) => {
            return _.isEqual(prev, curr);
        })).subscribe((result: Date) => {
            if (this.isWriteValue) {
                if (result) {
                    const valueText = DateTime.fromJSDate(result).toFormat('dd/MM/yyyy');
                    this._inputValue.setValue(valueText);
                }
                this.onChangeValue(result);
            }
        });
        this._inputValue.valueChanges.pipe(takeUntil(this.$destroy), distinctUntilChanged(), debounceTime(100)).subscribe(result => {
            try {
                const arrStr = result.split('/');
                if (!isNaN(arrStr[0]) && !isNaN(arrStr[1]) && !isNaN(arrStr[2])) {
                    const date = DateTime.fromFormat(result, 'dd/MM/yyyy');
                    if (date.isValid) {
                        if (typeof this.disabledDate === 'function') {
                            if (this.disabledDate(date.toJSDate())) {
                                this._inputValue.setValue(undefined);
                            } else {
                                this.control.setValue(date.toJSDate());
                                this.refDate.picker.hideOverlay();
                                this.refDate.nzOpen = false;
                            }
                        } else {
                            this.control.setValue(date.toJSDate());
                            this.refDate.picker.hideOverlay();
                            this.refDate.nzOpen = false;
                            // if (this.minDate || this.maxDate) {
                            //     const dateTimeCheck = DateTime.fromFormat(result, 'dd/MM/yyyy').toJSDate();
                            //     if (differenceInCalendarDays(dateTimeCheck, this.minDate) < 0) {
                            //         this._inputValue.setValue(undefined);
                            //         return;
                            //     }

                            //     if (differenceInCalendarDays(dateTimeCheck, this.maxDate) > 0) {
                            //         this._inputValue.setValue(undefined);
                            //         return;
                            //     }
                            // }
                        }
                    } else {
                        this.control.setValue(undefined);
                    }

                } else {
                    this.control.setValue(undefined);
                }

            } catch (e) {
                this.control.setValue(undefined);
            }
        });
    }

    //#region base ControlValueAccessor
    writeValue(obj: DateTime): void {
        if (obj) {
            if (DateTime.isDateTime(obj)) {
                this.value = obj.toJSDate();
            }
            else {
                this.value = obj;
            }
            //this.value = new Date(obj);
        } else if (this.isWriteValue) {
            this._inputValue.setValue(undefined);
        }
        this.isWriteValue = true;

    }

    registerOnChange(fn: () => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this._isDisabled = isDisabled;
    }

    //#endregion

    disabledDates = (current: Date): boolean => {
        let checkMinDate = false;
        if (this.minDate) {
            checkMinDate = checkMinDate || differenceInCalendarDays(current, (typeof this.minDate == "string") ? DateTime.fromFormat(this.minDate, 'dd/MM/yyyy').toJSDate() : this.minDate) < 0;
        }
        if (this.maxDate) {
            checkMinDate = checkMinDate || differenceInCalendarDays(current, (typeof this.maxDate == "string") ? DateTime.fromFormat(this.maxDate, 'dd/MM/yyyy').toJSDate() : this.maxDate) > 0;
        }
        return checkMinDate;
    }
}
