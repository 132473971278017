<div class="root">
    <div class="title" style="{{strStyleTitle}}">
        <ng-container *nzStringTemplateOutlet="title">
            {{title}}
        </ng-container>
    </div>
    <div class="action" style="{{strStyleAction}}">
        <ng-container *nzStringTemplateOutlet="action">
            {{action}}
        </ng-container>
    </div>
</div>
