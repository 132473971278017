<p class="modal-header">
    <span class="ant-modal-title">{{'NotificationSettings' | localize}}</span>
</p>
<form nz-form nzLayout="vertical" #editForm="ngForm" (ngSubmit)="save()" *ngIf="settings">
    
    <!-- <h4>{{"ReceiveNotifications" | localize}}</h4> -->
    <nz-form-item>
        <nz-form-label nzFor="text">{{"ReceiveNotifications" | localize}}</nz-form-label>
        <nz-form-control [nzExtra]="l('ReceiveNotifications_Definition')">
            <nz-switch name="ReceiveNotifications" [(ngModel)]="settings.receiveNotifications" [nzCheckedChildren]="checkedTemplate"
                [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
            <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
            <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
        </nz-form-control>
    </nz-form-item>

    
    <nz-form-item>
        <nz-form-label nzFor="text" *ngIf="settings.notifications.length">{{"NotificationTypes" | localize}}</nz-form-label>
        <nz-form-control [nzExtra]="notification.description" *ngFor="let notification of settings.notifications">
            <label nz-checkbox name="Notification_{{notification.name}}" [nzDisabled]="!settings.receiveNotifications"
                [(ngModel)]="notification.isSubscribed">{{notification.displayName}}</label>
        </nz-form-control>
    </nz-form-item>

    <p *ngIf="settings.notifications.length && !settings.receiveNotifications" class="text-red"><small>{{"ReceiveNotifications_DisableInfo" | localize}}</small>
    </p>

    <div class="modal-footer">
        <button nz-button type="button" [disabled]="saving" (click)="close()">
            <i nz-icon nzType="close"></i>
            {{"Cancel" | localize}}
        </button>
        <button nz-button [nzType]="'primary'" type="submit" [nzLoading]="saving">
            <i nz-icon nzType="save"></i> {{"Save" | localize}}
        </button>
    </div>
</form>