import { Injectable } from '@angular/core';
import { Observable } from '@node_modules/rxjs';
import { BaseStateService } from '@shared/base-state.service';
import { CommonServiceProxy } from '@shared/service-proxies/service-proxies';
import { HttpClient } from '@angular/common/http';
import { AppConsts } from '@shared/AppConsts';
import { AppUtilityService } from '../common/custom/utility.service';

interface State {
  reload: boolean;

  [proName: string]: any;
}

const initialState: State = {
  reload: false
};

@Injectable({
  providedIn: 'root'
})
export class CommonComboDataStateService extends BaseStateService<State> {
  reload$: Observable<boolean> = this.select((s) => s.reload);

  data$(key: string): Observable<any[]> {
    return this.select((state) => state[key]);
  }

  constructor(
    private combodataServiceProxy: CommonServiceProxy,
    //private restService: RestService,
    private _httpClient: HttpClient
    ) {
    super(initialState);
    this.getInitStateFromSession();
  }

  getData(opt: { type: 'table' | 'enum' | 'dictionary' | 'tree'; cascader?: any; name: string; keyCache: string; dataOption?: any }) {
    setTimeout(() => {
      const data = this.state[opt.keyCache];
      if (AppUtilityService.isNullOrEmpty(data) === false && (AppUtilityService.isNullOrEmpty(opt.dataOption) || opt.dataOption.length <= 0)) {
        return;
      }
      this.setState({ [opt.keyCache]: [] });
      if (opt.type === 'enum') {
        this.combodataServiceProxy.getAppEnum(opt.name).subscribe((d) => {
          this.setStateAndSession(opt.keyCache, d);
        });
      } else if (opt.type === 'tree') {
        const req: any = {
          cascaderId: opt.cascader,
          cascaderMa: opt.cascader,
          cascaderCode: opt.cascader,
          tableName: opt.name
        };
        if (opt.dataOption && opt.dataOption.length > 0) {
          this.setStateAndSession(opt.keyCache, opt.dataOption);
        } else {
          this.getfromdatabasetreeview(req).subscribe((d) => {
            this.setStateAndSession(opt.keyCache, d);
          });

        }
      } else {
        const req: any = {
          cascaderId: opt.cascader,
          cascaderMa: opt.cascader,
          cascaderCode: opt.cascader,
          tableName: opt.name
        };
        if(opt.dataOption)
        {
          if(opt.dataOption.cascaderFieldType == "list")
          {
            req.cascaderId = null;
            req.cascaderMa = null;
            req.cascaderCode = null;
            req.listcascaderCode = opt.cascader;
          }
        }
        this.combodataServiceProxy.getFromDataBase(req).subscribe((d) => {
          this.setStateAndSession(opt.keyCache, d);
        });
      }
    });
  }

  clearAll() {
    this.clearState(initialState);
    this.setState({
      reload: false
    });
    this.setState({
      reload: true
    });
  }

  clearByKey(name: string) {
    sessionStorage.removeItem(name);
    this.setState({ [name]: null });
  }

  setStateAndSession(proName, data) {
    this.setState({
      [proName]: data
    });
    const state = this.state;
    sessionStorage.setItem('commonComboDataState', JSON.stringify(state));
  }

  getInitStateFromSession() {
    const s = sessionStorage.getItem('commonComboDataState');
    if (!AppUtilityService.isNullOrEmpty(s)) {
      setTimeout(() => {
        this.setState(JSON.parse(s));
      });
    }
  }

  getfromdatabasetreeview2(req): Observable<any> {
    return this.combodataServiceProxy.getFromDataBaseTreeView(req);
  }
  getfromdatabasetreeview(req) {
    let url = AppConsts.remoteServiceBaseUrl + "/api/services/app/Common/GetFromDataBaseTreeView"
   return this._httpClient
        .post<any>(url,req);
    // return this.restService.request<any, any>(
    //   {
    //     method: 'POST',
    //     url: '/api/danh-muc/common/get-from-data-base-tree-view',
    //     body: req
    //   },
    //   { apiName: 'danhMuc' }
    // );
  }
}
