<form nz-form #changePasswordModalForm="ngForm" (ngSubmit)="save()" nzLayout="vertical">
    <nz-row>
        <nz-col nzSpan="24">
            <nz-form-item>
                <nz-form-label nzRequired nzFor="CurrentPassword">{{'CurrentPassword' | localize}}</nz-form-label>
                <nz-form-control [nzErrorTip]="currentPasswordValidationTpl" nzHasFeedback>
                    <input nz-input autocomplete="off" name="CurrentPassword" #currentPasswordInput="ngModel"
                        [(ngModel)]="currentPassword" placeholder="{{'PlaceholderTextInput' | localize}}" type="password"
                        required>
                    <ng-template #currentPasswordValidationTpl let-control>
                        <validation-messages [formCtrl]="control"></validation-messages>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
        </nz-col>
        <nz-col nzSpan="24">
            <nz-form-item>
                <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired nzFor="NewPassword">{{'NewPassword' |
                    localize}}</nz-form-label>
                <nz-form-control [nzErrorTip]="newPasswordValidationTpl" [nzSm]="24" [nzXs]="24" nzHasFeedback>
                    <input nz-input autocomplete="off" name="NewPassword" #NewPasswordInput="ngModel"
                        [(ngModel)]="password" placeholder="{{'PlaceholderTextInput' | localize}}" type="password"
                        validateEqual="NewPasswordRepeat" reverse="true"
                        [requireDigit]="passwordComplexitySetting.requireDigit"
                        [requireLowercase]="passwordComplexitySetting.requireLowercase"
                        [requireUppercase]="passwordComplexitySetting.requireUppercase"
                        [requireNonAlphanumeric]="passwordComplexitySetting.requireNonAlphanumeric"
                        [requiredLength]="passwordComplexitySetting.requiredLength" required>
                    <ng-template #newPasswordValidationTpl let-control>
                        <ul *ngIf="control.errors">
                            <li [hidden]="!control.errors.requireDigit">{{"PasswordComplexity_RequireDigit_Hint" |
                                localize}}</li>
                            <li [hidden]="!control.errors.requireLowercase">{{"PasswordComplexity_RequireLowercase_Hint"
                                | localize}}</li>
                            <li [hidden]="!control.errors.requireUppercase">{{"PasswordComplexity_RequireUppercase_Hint"
                                | localize}}</li>
                            <li [hidden]="!control.errors.requireNonAlphanumeric">
                                {{"PasswordComplexity_RequireNonAlphanumeric_Hint" | localize}}</li>
                            <li [hidden]="!control.errors.requiredLength">{{"PasswordComplexity_RequiredLength_Hint" |
                                localize:passwordComplexitySetting.requiredLength}}</li>
                        </ul>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
        </nz-col>
        <nz-col nzSpan="24">
            <nz-form-item>
                <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired nzFor="NewPasswordRepeat">{{'NewPasswordRepeat' |
                    localize}}</nz-form-label>
                <nz-form-control [nzErrorTip]="newPasswordRepeatValidationTpl" [nzSm]="24" [nzXs]="24" nzHasFeedback>
                    <input nz-input autocomplete="off" name="NewPasswordRepeat" #NewPasswordRepeatInput="ngModel"
                        [(ngModel)]="confirmPassword" placeholder="{{'PlaceholderTextInput' | localize}}" type="password"
                        validateEqual="NewPassword" [requireDigit]="passwordComplexitySetting.requireDigit"
                        [requireLowercase]="passwordComplexitySetting.requireLowercase"
                        [requireUppercase]="passwordComplexitySetting.requireUppercase"
                        [requireNonAlphanumeric]="passwordComplexitySetting.requireNonAlphanumeric"
                        [requiredLength]="passwordComplexitySetting.requiredLength" reverse="false" required>
                    <ng-template #newPasswordRepeatValidationTpl let-control>
                        <ul *ngIf="control.errors">
                            <li [hidden]="!control.errors.requireDigit">{{"PasswordComplexity_RequireDigit_Hint" |
                                localize}}</li>
                            <li [hidden]="!control.errors.requireLowercase">{{"PasswordComplexity_RequireLowercase_Hint"
                                | localize}}</li>
                            <li [hidden]="!control.errors.requireUppercase">{{"PasswordComplexity_RequireUppercase_Hint"
                                | localize}}</li>
                            <li [hidden]="!control.errors.requireNonAlphanumeric">
                                {{"PasswordComplexity_RequireNonAlphanumeric_Hint" | localize}}</li>
                            <li [hidden]="!control.errors.requiredLength">{{"PasswordComplexity_RequiredLength_Hint" |
                                localize:passwordComplexitySetting.requiredLength}}</li>
                            <li [hidden]="control.valid">{{"PasswordsDontMatch" | localize}}</li>
                        </ul>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
        </nz-col>
    </nz-row>
    <div class="modal-footer">
        <button nz-button type="button" [disabled]="saving" (click)="close()">
            <i nz-icon nzType="close"></i> {{"Cancel" | localize}}
        </button>
        <button nz-button [nzType]="'primary'" type="submit" [disabled]="!changePasswordModalForm.form.valid || saving"
            [nzLoading]="saving">
            <i nz-icon nzType="save"></i> {{"Save" | localize}}
        </button>
    </div>
</form>
