import { Component, EventEmitter, forwardRef, Injector, Input, OnInit, Output } from '@angular/core';

import { HttpClient, HttpEventType } from '@angular/common/http';
import { AppConsts } from '@shared/AppConsts';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileUploadServiceProxy, UtilityServiceProxy } from '@shared/service-proxies/service-proxies';


@Component({
    selector: 'ord-file-dinh-kem',
    templateUrl: './file-dinh-kem.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => FileDinhKemComponent),
        multi: true
    }]
})
export class FileDinhKemComponent  implements OnInit {
    constructor(
        injector: Injector,
        private _httpClient: HttpClient,
        ) {
    }

    ngOnInit(): void {
        if(this.fileGuidId != null)
        {
            this._httpClient.get((AppConsts.remoteServiceBaseUrl + '/File/GoToViewByte?imgId='+this.fileGuidId), { reportProgress: true, observe: 'events' })
            .subscribe(event => {
                if (event.type === HttpEventType.UploadProgress)
                    this.progress = Math.round(100 * event.loaded / event.total);
                else if (event.type === HttpEventType.Response) {
                    let dataRes = event.body as any;
                    if (dataRes != null) {
                        this.fileBytes = dataRes.result;
                    }
                }
            });
        }
    }

    fileToUpload: File = null;

    noimageDefault = AppConsts.appBaseUrl + '/assets/common/images/noimage2.png';
    progress: number;
    message: string;
    @Input() textBtn: string = "Upload File";
    @Input() isViewChiTiet: boolean = false;
    @Input() fileGuidId: any = null;
    @Input() height: Number = 165;
    @Input() width: Number = 200;
    @Output() fileGuidIdChange = new EventEmitter();
    fileBytes:any = null;
    dinhDangFileUpLoad = ['jpg', 'jpeg', 'png'];
    uploadFile = (files) => {
        if (files.length === 0) {
            return;
        }
        //let fileToUpload = <File>getArrFile[0];
        let fileToUpload = <File>files[0];

        if (fileToUpload.size > 5242880) { //5MB
            abp.notify.error("File upload không được quá 5M");
            return;
        }
        let fileType = fileToUpload.name.split('.').pop().toLowerCase();
        if(this.dinhDangFileUpLoad.length > 0 && !this.dinhDangFileUpLoad.includes(fileType)){
            abp.notify.error("Định dạng file sai");
            return;
        }
        const formData = new FormData();
        formData.append('file', fileToUpload, fileToUpload.name);
        this._httpClient.post(AppConsts.remoteServiceBaseUrl + '/File/UploadPicture', formData, { reportProgress: true, observe: 'events' })
            .subscribe(event => {
                if (event.type === HttpEventType.UploadProgress)
                    this.progress = Math.round(100 * event.loaded / event.total);
                else if (event.type === HttpEventType.Response) {
                    this.message = 'Upload success.';
                    let dataRes = event.body;
                    if (dataRes != null) {
                        this.onFinished(dataRes);
                    }
                }
            });
    }

    onFinished(item) {
        let data = item.result;
        this.fileBytes = data.fileBytes;
        this.fileGuidId = data.guidId;
        this.fileGuidIdChange.emit(this.fileGuidId); // two way binding
    }

    removeFile() {
        this.fileGuidId = null;
        this.fileBytes = null;
        this.fileGuidIdChange.emit(this.fileGuidId); // two way binding
    }

    getTypeFile(item) {
        let checkFileType = item.fileName.split('.').pop();
        var fileType;
        if (checkFileType == "txt") {
            fileType = "text/plain";
        }
        if (checkFileType == "pdf") {
            fileType = "application/pdf";
        }
        if (checkFileType == "doc" || checkFileType == "docx") {
            fileType = "application/vnd.ms-word";
        }
        if (checkFileType == "xls" || checkFileType == "xlsx") {
            fileType = "application/vnd.ms-excel";
        }
        if (checkFileType == "png") {
            fileType = "image/png";
        }
        if (checkFileType == "jpg" || checkFileType == "jpeg") {
            fileType = "image/jpeg";
        }
        if (checkFileType == "gif") {
            fileType = "image/gif";
        }
        if (checkFileType == "csv") {
            fileType = "text/csv";
        }
        return fileType;
    }
}
