import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as $ from 'jquery';
import { DateTime } from 'luxon';
import moment from 'moment';

@Injectable()
export class AppUtilityService {
    constructor() {

    }

    static removeDau(str: string): string {
        str = str.toLowerCase();
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
        str = str.replace(/đ/g, 'd');
        str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, ' ');
        str = str.replace(/ + /g, ' ');
        str = str.trim();
        return str;
    }

    static isNotNull(input: any): boolean {
        return !this.isNullOrEmpty(input);
    }

    static isWhitespace(value: string): boolean {
        return (value || '').trim().length === 0;
    }

    static IsNullValidateForm = function (_htmlId: string) {
        if (this.isNullOrEmpty(_htmlId)) {
            return true;
        }
        const _listElement = document.getElementById(_htmlId).querySelectorAll('.custom-error-validate') as NodeListOf<HTMLElement>;
        if (_listElement != null && _listElement.length > 0) {
            _listElement.forEach((_element) => {
                _element.style.display = 'inline';
            });
            return true;
        } else {
            return false;
        }
    };

    static searchVietTat(str) {
        if (this.isNullOrEmpty(str)) {
            return str;
        }
        let ret = '';
        const spl = str.split(' ');
        if (this.isNotAnyItem(spl) === false) {
            spl.forEach((s) => {
                if (s.length > 0) {
                    ret = ret + s[0];
                }
            });
        }
        return this.getFullTextSearch(_.cloneDeep(ret));
    }

    static getFullTextSearch(str) {
        if (this.isNullOrEmpty(str)) {
            return str;
        }
        str += '';
        str = this.removeDau(str);
        str = str.replace(/ /g, '');
        return str;
    }
    static PauseValidateForm = function (_htmlId: string) {
        if (this.isNullOrEmpty(_htmlId)) {
            return;
        }
        else {
            var form = document.getElementById(_htmlId);
            if (form) {
                var _listElement = document.getElementById(_htmlId).querySelectorAll('.custom-error-validate') as NodeListOf<HTMLElement>;
                if (_listElement != null && _listElement.length > 0) {
                    _listElement.forEach((_element) => {
                        _element.style.display = 'none';
                    });
                }
            }
        }
    }

    static removeAccents(str) {
        let AccentsMap = [
            'aàảãáạăằẳẵắặâầẩẫấậ',
            'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
            'dđ', 'DĐ',
            'eèẻẽéẹêềểễếệ',
            'EÈẺẼÉẸÊỀỂỄẾỆ',
            'iìỉĩíị',
            'IÌỈĨÍỊ',
            'oòỏõóọôồổỗốộơờởỡớợ',
            'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
            'uùủũúụưừửữứự',
            'UÙỦŨÚỤƯỪỬỮỨỰ',
            'yỳỷỹýỵ',
            'YỲỶỸÝỴ',
        ];
        for (let i = 0; i < AccentsMap.length; i++) {
            let re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
            let char = AccentsMap[i][0];
            str = str.replace(re, char);
        }
        return str;
    }

    // static isNullOrEmpty(input: any): boolean {
    //     let res = false;
    //     if (typeof (input) === 'undefined')
    //         res = true;
    //     if (input == null)
    //         res = true;
    //     input = String(input);
    //     if (input == '')
    //         res = true;
    //     return res;
    // }
    static isNullOrEmpty(input: any): boolean {
        return !(typeof input !== 'undefined' && input && input !== '');
    }

    static isNullOrWhiteSpace(input: any): boolean {
        if (typeof input === 'undefined' || input == null || input == 'null') {
            return true;
        }
        if (typeof input == 'object') {
            return typeof input === 'undefined' || input === null || input === '';
        }
        if (typeof input == 'string') {
            return input.replace(/\s/g, '').length < 1;
        }
    }
    static validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    static validateNumber = (number) => {
        const re = /^[0-9]+$/;
        return re.test(String(number).toLowerCase());
    };
    static validateMoment = (_dateTime) => {
        return _dateTime.isValid();
    };

    static isNotAnyItem(input): boolean {
        return (this.isNullOrEmpty(input) || input.length === 0);
    }
    static compareDatetime = (_dateTime1, _dateTime2, isFullHour) => {
        let date1 = _dateTime1;
        let date2 = _dateTime2;
        if (typeof _dateTime1 == 'string') {
            var checkValidDate = moment(_dateTime1, 'DD/MM/YYYY', true).isValid();
            if (checkValidDate) {
                date1 = DateTime.fromFormat(_dateTime1, 'dd/MM/yyyy').toJSDate();
            }
            else {
                return;
            }
        } else if (typeof _dateTime1 == 'object') {
            if (DateTime.isDateTime(_dateTime1)) {
                date1 = _dateTime1.toJSDate();
            }
        }

        if (typeof _dateTime2 == 'string') {
            var checkValidDate = moment(_dateTime2, 'DD/MM/YYYY', true).isValid();
            if (checkValidDate) {
                date2 = DateTime.fromFormat(_dateTime2, 'dd/MM/yyyy').toJSDate();
            }
            else {
                return;
            }
        }
        else if (typeof _dateTime2 == 'object') {
            if (DateTime.isDateTime(_dateTime2)) {
                date2 = _dateTime2.toJSDate();
            }
        }

        if (!isFullHour) {
            date1 = AppUtilityService.setDefaultDatetime(date1);
            date2 = AppUtilityService.setDefaultDatetime(date2);
        }

        return date1.getTime() - date2.getTime();
    };

    static setDefaultDatetime(_dateTime) {
        _dateTime.setHours(0, 0, 0, 0);
        return _dateTime;
    }

    static validatePassword(value: string) {
        const check = value.match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/);
        //const check = value.match(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z]).{8,}$/);
        return !!check;
    }
    static validateNumberPhone(value: string) {
        var regexPhoneNumber = abp.setting.get("App.MySMSSetting.PhoneNumberPattern");
        const check = value.match(regexPhoneNumber);
        return !!check;
    }
//string regex ipv6 compressed?
    static validateAddressIpv4(value: string) {
        var regexIpv4 = /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/;
        const check = value.match(regexIpv4);
        return !!check;
    }
    static validateAddressIpv6(value: string) {
        var regexIpv6 = /(([0-9a-fA-F]{1,4}:){7}([0-9a-fA-F]{1,4}))/;
        var regexIpv6_2 = /^(([0-9A-Fa-f]{1,4}(?::[0-9A-Fa-f]{1,4})* )?)::((?:[0-9A-Fa-f]{1,4}(?::[0-9A-Fa-f]{1,4})*)?)$/ ; // nén
        const check_2 = value.match(regexIpv6_2);
        const check = value.match(regexIpv6);
        return !!check || !!check_2;
    }
    static formatCurrency(value) {
        try {
            const num = Number(value.toString().replace(/[^0-9.-]+/g, ''));
            //var numRound = num.toFixed(appSession.setting.SoChuSoThapPhan);
            const numRound = num.toFixed(0);
            const parts = numRound.toString().split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return parts.join('.');
        } catch (err) {
            return '0';
        }
    }

    // chuyển giá trị số --> string để truyền vào form select
    static convertIntToStringPropertyOfObject(obj): void {
        if (this.isNullOrEmpty(obj)) {
            return;
        }
        if (Array.isArray(obj)) {
            if (this.isNotAnyItem(obj)) {
                return;
            }
            obj.forEach((it) => {
                this.convertIntToStringPropertyOfObject(it);
            });
        } else {
            Object.keys(obj).forEach((key) => {
                const typeKey = typeof obj[key];
                if (typeKey === 'number') {
                    obj[key] = '' + obj[key];
                } else if (typeKey === 'object') {
                    this.convertIntToStringPropertyOfObject(obj[key]);
                }
            });
        }
    }
    static isVisibleJquery(id: string) {
        return $('#' + id).is(':visible');
    }

    static convertBase64ToPdf(base64) {

        // Decode base64 using atob method
        var raw = window.atob(base64);
        // Create an array to store the decoded data
        var uInt8Array = new Uint8Array(raw.length);
        // blob can only receive binary encoding, need to talk about base64 converted to binary and then stuffed
        for (var i = 0; i < raw.length; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
        // A return value is given here. In other methods, you can get the converted blob by using the base64 encoding.

        const blob = new Blob([uInt8Array], { type: 'application/pdf' })

        return blob;
    }

    static isUnicode(str) {
        return /[^\u0000-\u00ff]/.test(str);
    }
}
