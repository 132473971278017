<div class="alain-default__aside-inner">
    <sidebar-nav class="d-block"></sidebar-nav>
    <!-- <sidebar-nav class="d-block py-lg"></sidebar-nav> -->

    <div *ngIf="appSession.tenant && !collapsed" class="countSent" >
        <div class="title-table">{{'SoSMSConLai' | localize}}</div>
        <ul id="remain-sms" style="padding-left: 20px;" *ngIf="appSession.tenant.myTelRemaining || appSession.tenant.mptRemaining || appSession.tenant.telenorRemaining || appSession.tenant.ooredooRemaining">
            <li>
                <span class="ml-1">
                    <span class="font-weight-bold"> Mytel: </span> 
                    <span *ngIf="appSession.tenant.myTelRemaining">{{appSession.tenant.myTelRemaining | number}}</span>
                    <span *ngIf="!appSession.tenant.myTelRemaining">0</span>
                </span>
            </li>
            <li>
                <span class="ml-1">
                    <span class="font-weight-bold"> MPT: </span>
                    <span *ngIf="appSession.tenant.mptRemaining">{{appSession.tenant.mptRemaining | number}}</span>
                    <span *ngIf="!appSession.tenant.mptRemaining">0</span>
                </span>
            </li>
            <li>
                <span class="ml-1">
                    <span class="font-weight-bold"> Telenor: </span> 
                    <span *ngIf="appSession.tenant.telenorRemaining">{{appSession.tenant.telenorRemaining | number}}</span>
                    <span *ngIf="!appSession.tenant.telenorRemaining">0</span>
                </span>
            </li>
            <li>
                <span class="ml-1">
                    <span class="font-weight-bold"> Ooredoo: </span> 
                    <span *ngIf="appSession.tenant.ooredooRemaining">{{appSession.tenant.ooredooRemaining | number}}</span>
                    <span *ngIf="!appSession.tenant.ooredooRemaining">0</span>
                </span>
            </li>
            <li>
                <span class="ml-1">
                    <span class="font-weight-bold"> Mectel: </span> 
                    <span *ngIf="appSession.tenant.mectelRemaining">{{appSession.tenant.mectelRemaining | number}}</span>
                    <span *ngIf="!appSession.tenant.mectelRemaining">0</span>
                </span>
            </li>
        </ul>
        
        <div *ngIf="!appSession.tenant.myTelRemaining && !appSession.tenant.mptRemaining && !appSession.tenant.telenorRemaining && !appSession.tenant.ooredooRemaining && !appSession.tenant.mectelRemaining">
            <span>{{ 'YouHave0MessageLeft' | localize }}</span>
                <button nz-button nzType="link" (click)="gotoUrl('app/message-package/buy-message')">
                    {{'ClickHereToBuy' | localize}}
                </button>
        </div>
    </div>
</div>
