import { Component, Injector, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AppUtilityService } from '@app/shared/common/custom/utility.service';
import { ModalComponentBase } from '@shared/common/modal-component-base';

@Component({
  selector: 'upload-multi-file',
  templateUrl: './upload-multi-file.component.html',
})
export class UploadMultiFileComponent extends ModalComponentBase implements OnInit {
  @Input() Title = "";
  @Input() listTepDinhKem;
  @Input() isView;

  @Input() urlApi = '';
  @Input() uploadUrl: string = '';
  @Input() viewUrl: string = '';
  @Input() downloadUrl: string = '';
  constructor(injector: Injector,
              protected sanitizer: DomSanitizer) {
    super(injector);
  }

  ngOnInit(): void {
  }

//   getPath(){
//     return this.sanitizer.bypassSecurityTrustResourceUrl(this.path)
//   }

  addTepDinhKem(){
      this.listTepDinhKem.push({})
  }

  xoaTepDinhKem(idx)
  {
      this.listTepDinhKem.splice(idx,1)
  }
  
  save(){
        if(AppUtilityService.IsNullValidateForm("formTepDinhKemValidate"))
        {
            abp.notify.error('Bạn cần tải đầy đủ những tài liệu yêu cầu');
            return;
        }
      this.nzModalRef.close(this.listTepDinhKem);
  }
}
