import {Component, EventEmitter, forwardRef, Injector, Input, OnInit, Output} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UtilityServiceProxy } from '@shared/service-proxies/service-proxies';

import * as _ from 'lodash';
import { Observable, Observer } from 'rxjs';
import { AppConsts } from '@shared/AppConsts';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'dropzone-file-upload',
    template: `
       <nz-upload nzType="drag" [nzMultiple]="false" (nzChange)="uploadTepDinhKem($event)" [nzAction]="uploadImportUrls" 
       [nzDisabled]="disabled"
       [(nzFileList)]="fileList" [nzAccept]="acceptType">
                        <p class="ant-upload-drag-icon">
                            <i nz-icon nzType="upload"></i>
                        </p>
                        <p class="ant-upload-text">{{hintUpload}}</p>
        </nz-upload>
    `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DropzoneFileUploadComponent),
        multi: true
    }]
})

export class DropzoneFileUploadComponent extends AppComponentBase implements OnInit {
    notData = true;
    @Input() isView = false;
    @Input() disabled = false;
    @Input() sLimit? = 15;
    @Input() folderName : '';
    @Input() sModel: '';
    @Output() sModelChange = new EventEmitter();
    @Input() acceptType: string = '.xlsx,.xls';
    @Input() uploadImportUrl = AppConsts.remoteServiceBaseUrl + '/api/FileUpload/UploadFileTemp?folderName=';
    fileList  = [];
    uploadImportUrls="";
    hintUpload = this.l("NhanHoacKeoThaFileVaoVungNay");
    constructor(
        injector: Injector,
        private _service: UtilityServiceProxy) {
            super(injector);
    }

    ngOnInit() {
        this.uploadImportUrls = this.uploadImportUrl + this.folderName;
    }

    beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]): Observable<boolean> =>
    new Observable((observer: Observer<boolean>) => {
      var indexOfPoint = file.name.lastIndexOf(".");
      var fileType = file.name.substring(indexOfPoint, file.name.length);
      const acceptTypeCheck = this.acceptType.includes(fileType);
      if (!acceptTypeCheck) {
        abp.notify.error(this.l("KhongHoTroDinhDangFile") + this.acceptType)
        observer.complete();
        return;
      }
      const mb = file.size! / 1024 / 1024;
      const isCheckSize = mb < this.sLimit;
      if (!isCheckSize) {
          abp.notify.error(this.l("FileKhongDuocQua") + " " + this.sLimit + " MB")
        observer.complete();
        return;
      }
      observer.next(acceptTypeCheck && isCheckSize);
      observer.complete();
    });

    public uploadTepDinhKem({
        file,
        fileList
    }: NzUploadChangeParam): void {
        if (file.status == 'error') {
            file.message = file.error.error.message;
            fileList = [];
            this.fileList = [];
        }

        if (file.response && file.response.success == true) {
            var dataRes = file.response;
            this.sModel = file.response;
            this.sModelChange.emit(this.sModel);
            this.fileList = [];
        }

    }
}
