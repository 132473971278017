import { formatNumber } from '@angular/common';
import { Injectable, Injector, OnInit } from '@angular/core';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { LocalizationService } from 'abp-ng2-module';
import { differenceInCalendarDays } from 'date-fns';
@Injectable()
export class SuperHelperService implements OnInit {
    today = new Date();
    appSession: AppSessionService;
    localization: LocalizationService;

    codeLanguage = 'vi';

    constructor(injector: Injector) {
        this.appSession = injector.get(AppSessionService);
        this.localization = injector.get(LocalizationService);
        this.codeLanguage = this.localization.currentLanguage.name;
    }

    ngOnInit() {}

    //format number
    formatterNumber = (value: number) => (value ? formatNumber(value, this.codeLanguage) : null);
    parser = (value: string) => {
        return value
            .replace(/\$\s?|(\.*)/g, '')
            .replace(/\s/g, '')
            .replace('.', '')
            .replace(',', '');
    };

    //disabled date
    disabledDaysInThePast = (current: Date): boolean => differenceInCalendarDays(current, this.today) < 0;
    disabledFutureDate = (current: Date): boolean => differenceInCalendarDays(current, this.today) > 0;

    //#region  view or download file by url
    viewOrDownloadFileByUrl = (
        url: string,
        method: 'GET' | 'POST' = 'GET',
        selector: string,
        isDownload: boolean,
        target: '_blank' | null = null,
    ) => {
        try {
            let xhr = new XMLHttpRequest();
            xhr.open(method, url, true);
            xhr.responseType = 'blob';
            xhr.onload = (e) => {
                const blob = xhr.response;
                const blobURL = URL.createObjectURL(blob);
                if (isDownload) {
                    const el = document.createElement('a');
                    el.style.display = 'none';
                    el.href = window.URL.createObjectURL(blob);
                    el.download = url.slice(url.lastIndexOf('\\'));
                    el.dispatchEvent(new MouseEvent('click'));
                } else if (target === '_blank') {
                    window.open(blobURL);
                } else {
                    document.querySelector(selector).setAttribute('src', blobURL);
                }
            };
            xhr.send();
        } catch (e) {
            return new Error(e.message);
        }
    };
    //#endregion

    //#region Remove dấu
    removeVietnameseTones(str) {
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
        str = str.replace(/đ/g, 'd');
        str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
        str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
        str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
        str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
        str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
        str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
        str = str.replace(/Đ/g, 'D');
        // Some system encode vietnamese combining accent as individual utf-8 characters
        // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
        str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
        str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
        // Remove extra spaces
        // Bỏ các khoảng trắng liền nhau
        str = str.replace(/ + /g, ' ');
        str = str.trim();
        // Remove punctuations
        // Bỏ dấu câu, kí tự đặc biệt
        str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|`|-|{|}|\||\\/g, ' ');
        return str;
    }
    //#endregion
}
