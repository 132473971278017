<div class="alain-default__nav-item" nz-dropdown nzTrigger="click" nzPlacement="bottomRight" [nzDropdownMenu]="noticeMenu">
    <nz-badge [nzCount]="unreadNotificationCount">
        <i nz-icon nzType="bell" class="alain-default__nav-item-icon"></i>
    </nz-badge>
    <nz-dropdown-menu #noticeMenu="nzDropdownMenu">
        <div nz-menu class="fix-notification">
            <div *ngIf="loading" class="mx-lg p-lg">
                <nz-spin></nz-spin>
            </div>
            <nz-card [nzTitle]="nzTitle" [nzLoading]="loading" [nzExtra]="extra" [nzBordered]="false" class="ant-card__body-nopadding" style="margin-bottom: 0px !important;">
                <ng-template #nzTitle>
                    {{unreadNotificationCount}} {{'NewNotifications' | localize}}
                </ng-template>
                <ng-template #extra>
                    <span (click)="openNotificationSettingsModal()" nz-icon nz-tooltip [nzTooltipTitle]="l('Settings')" style="font-size: 18px; cursor: pointer;" nzType="setting" nzTheme="outline"></span>
                    <!-- <a >
                        {{'Settings' | localize}}
                    </a> -->
                </ng-template>
                <div *ngIf="notifications?.length === 0; else listTpl" class="notice-icon__notfound">
                    <p>{{"ThereIsNoNotification" | localize}}</p>
                </div>
                <ng-template #listTpl>
                    <nz-list [nzDataSource]="notifications" [nzRenderItem]="item">
                        <ng-template #item let-item>
                            <nz-list-item >
                                <nz-list-item-meta [nzTitle]="nzTitle" [nzDescription]="nzDescription" [nzAvatar]="nzAvatar">
                                    <ng-template #nzTitle>
                                        <a href="javascript:void(0);" (click)="gotoUrl(item)" *ngIf="item.url">{{item.text}}</a>
                                        <span *ngIf="!item.url">{{item.text}}</span>
                                    </ng-template>
                                    <ng-template #nzDescription>
                                        <div *ngIf="item.creationTime" class="notice-icon__item-time">
                                            {{item.creationTime | luxonFromNow}}
                                            <span *ngIf="item.isUnread">
                                                <a href="javascript:void(0);" *ngIf="item.state=='UNREAD'"
                                                    class="notice-icon__item-extra"
                                                    (click)="setNotificationAsRead(item); $event.preventDefault(); $event.stopPropagation();">{{"SetAsRead" | localize}}</a>
                                            </span>
                                        </div>
                                    </ng-template>
                                    <ng-template #nzAvatar>
                                        <i nz-icon [nzType]="item.icon" [ngStyle]="{'color': item.color}" class="display-3"></i>
                                    </ng-template>
                                </nz-list-item-meta>
                            </nz-list-item>
                        </ng-template>
                    </nz-list>
                    <div class="notice-icon__clear" *ngIf="notifications.length">
                        <a href="javascript:;" (click)="setAllNotificationsAsRead();" *ngIf="unreadNotificationCount">{{"SetAllAsRead" | localize}}</a>
                        <nz-divider nzType="vertical" *ngIf="unreadNotificationCount"></nz-divider>
                        <a routerLink="notifications">{{"SeeAllNotifications" | localize}}</a>
                    </div>
                </ng-template>
            </nz-card>

        </div>
    </nz-dropdown-menu>
</div>
