import { FeatureCheckerService, PermissionCheckerService } from 'abp-ng2-module';

import { Injectable } from '@angular/core';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { Menu, MenuService } from '@delon/theme';
import { AppConsts } from '@shared/AppConsts';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {
    webSiteRootAddress = AppConsts.webSiteRootAddress ?? '/';
    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _featureCheckerService: FeatureCheckerService,
        private _appSessionService: AppSessionService,
        private _localizationService: AppLocalizationService,
        private _ngAlainMenuService: MenuService,
    ) { }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'dashboard', '/app/admin/host-dashboard'),
            // new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'dashboard', '/app/main/dashboard'),
            // new AppMenuItem('Tenants', 'Pages.Tenants', 'bars', '/app/admin/tenants'),
            // new AppMenuItem('Editions', 'Pages.Editions', 'shopping', '/app/admin/editions'),

            //HOST
            new AppMenuItem('Pages_QuanLyKhachHang', 'Pages.QuanLyKhachHang', 'team', '/app/manage-customers/choose-menu-customer'),

            //new AppMenuItem('Pages_DanhMuc_CongTacVien', 'Pages.DanhMuc.CongTacVien', 'solution', '/app/category/collaborator'),
            //new AppMenuItem('Agency', 'Pages.Agency', 'apartment', '/app/manage-agency/list-agency'),

            new AppMenuItem('Pages_PhieuMuaDichVu', 'Pages.Host.PurchasedOrder', 'shopping-cart', '/app/purchased-order/choose-menu-purchased-order'),

            new AppMenuItem('Pages_QuanLyNapTien', 'Pages.QuanLyNapTien', 'credit-card', '/app/deposit-management/choose-menu-deposit-management'),

            // new AppMenuItem('Pages_QuanLyNapTien', 'Pages.QuanLyNapTien', 'credit-card', '/app/deposit-management/deposit-approval'),

            new AppMenuItem(
                'Pages_QuanLyBrandName',
                'Pages.QuanLyBrandName',
                'safety-certificate',
                '/app/manage-brand-name/choose-menu-brand-name',
                [
                    // new AppMenuItem( 'Pages_QuanLyBrandName_XacNhanDangKyBrandName', 'Pages.QuanLyBrandName.XacNhanDangKyBrandName', 'bars', '/app/manage-brand-name/confirm-registration'),
                    // new AppMenuItem( 'Pages_QuanLyBrandName_TraCuuBrandName', 'Pages.QuanLyBrandName.TraCuuBrandName', 'bars', '/app/manage-brand-name/list-brand-name'),
                ],
            ),

            new AppMenuItem('Pages_Host_QuanLyTinNhan_TinNhanKhongHopLe', 'Pages.Host.QuanLyTinNhan', 'message', '/app/host-message-manager/invalid-message'),

            new AppMenuItem('Pages_BaoCao', 'Pages.BaoCao', 'bar-chart', '/app/report/choose-menu-report', [
                //new AppMenuItem('Pages_BaoCao_LichSuGuiTin', 'Pages.BaoCao.LichSuGuiTin', 'bars', '/app/report/message-history'),
            ]),

            new AppMenuItem('Pages_Notification', 'Pages.Notification', 'bell','/app/notification-to-tenant/list-notifications'),
            // new AppMenuItem('Pages_ChinhSachGiaMyTel', 'Pages.ChinhSachGiaMytel.GiaDichVu', 'dollar-circle', '/app/mytel-price-policy/package-price'),

            // new AppMenuItem('Pages_DanhMuc', 'Pages.DanhMuc', 'book', '/app/category/choose-menu-category', [
            //     new AppMenuItem('Pages_DanhMuc_Tinh', 'Pages.DanhMuc.Tinh', 'environment', '/app/category/region'),
            //     new AppMenuItem('Pages_DanhMuc_Huyen', 'Pages.DanhMuc.Huyen', 'environment', '/app/category/district'),
            //     new AppMenuItem('Pages_DanhMuc_Xa', 'Pages.DanhMuc.Xa', 'environment', '/app/category/commune'),
            //     new AppMenuItem('Pages_DanhMuc_DauSo', 'Pages.DanhMuc.DauSo', 'phone', '/app/category/prefix-phone-number'),
            //     new AppMenuItem('Pages_DanhMuc_NganHang', 'Pages.DanhMuc.NganHang', 'bank', '/app/category/bank'),
            //     new AppMenuItem('Pages_DanhMuc_TuHanChe', 'Pages.DanhMuc.TuHanChe', 'close-square', '/app/category/prohibited-keyword'),
            //     new AppMenuItem('Pages_DanhMuc_NguoiHoTro', 'Pages.DanhMuc.NguoiHoTro', 'user-switch', '/app/category/supporter'),
            // ]),

            //TENANT
            new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'dashboard', '/app/tenant-dashboard'),
            new AppMenuItem('Pages_NhanTin', 'Pages.NhanTin', 'message', '/app/message/list-campaign', []),
            new AppMenuItem('Pages_BrandName', 'Pages.BrandName', 'safety-certificate', '/app/brand-name/select-menu', []),
            //new AppMenuItem('Pages_GoiTinNhan', 'Pages.GoiTinNhan', 'inbox', '/app/message-package/select-menu', []),
            //new AppMenuItem('Pages_QuanLySoDuTaiKhoan', 'Pages.QuanLySoDuTaiKhoan', 'wallet', '/app/account-balance/select-menu-account-balance', []),
            new AppMenuItem('Pages_DanhBa', 'Pages.DanhBa', 'solution', '/app/contact/contact', []),
            new AppMenuItem('Pages_Tenant_BaoCao', 'Pages.Tenant.BaoCao', 'bar-chart', '/app/tenant-report/select-menu', []),

            //new AppMenuItem('Hướng dẫn sử dụng', 'Pages.HuongDanSuDung', 'check-circle', '/app/he-thong/xem-huong-dan-su-dung'),

            new AppMenuItem('Administration', 'Pages.Administration', 'appstore', '/app/admin/select-menu-admin', [
                //new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'team', '/app/admin/organization-units'),
                //new AppMenuItem('Roles', 'Pages.Administration.Roles', 'safety', '/app/admin/roles'),
                //new AppMenuItem('Users', 'Pages.Administration.Users', 'user', '/app/admin/users'),
                //new AppMenuItem('Languages', 'Pages.Administration.Languages', 'global', '/app/admin/languages'),
                //new AppMenuItem('LanguageTexts', 'Pages.Administration.LanguageTexts', 'global', '/app/admin/languagetexts'),
                //new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', 'book', '/app/admin/audit-logs'),
                //new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'lock', '/app/admin/maintenance'),
                // new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
                // new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
                //new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'setting', '/app/admin/host-settings'),
                //new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'setting', '/app/admin/tenant-settings'),
            ]),
        ]);
    }

    mapToNgAlainMenu() {
        let menu = this.getMenu();
        let ngAlainRootMenu = <Menu>{
            text: this._localizationService.l(menu.name),
            group: false,
            hideInBreadcrumb: true,
            children: [],
        };
        this.generateNgAlainMenus(ngAlainRootMenu.children, menu.items);

        let ngAlainMenus = [ngAlainRootMenu];
        this._ngAlainMenuService.add(ngAlainMenus);
    }

    generateNgAlainMenus(ngAlainMenus: Menu[], appMenuItems: AppMenuItem[]) {
        appMenuItems.forEach((item) => {
            let ngAlainMenu: Menu;

            ngAlainMenu = {
                text: this._localizationService.l(item.name),
                link: item.route,
                icon: {
                    type: 'icon',
                    value: item.icon,
                    //theme :'fill',
                },
                hide: !this.showMenuItem(item),
            };

            if (item.items && item.items.length > 0) {
                ngAlainMenu.children = [];
                this.generateNgAlainMenus(ngAlainMenu.children, item.items);
            }

            ngAlainMenus.push(ngAlainMenu);
        });
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName && this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                return this.checkChildMenuItemPermission(subMenuItem);
            } else if (!subMenuItem.permissionName) {
                return true;
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
            hideMenuItem = true;
        }

        if (hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }
}
