import { Component, Injector, ViewEncapsulation, OnInit } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { NotificationServiceProxy, UserNotification, UserNotificationState } from '@shared/service-proxies/service-proxies';
import { PagedListingComponentBase, PagedRequestDto } from '@shared/common/paged-listing-component-base';
import { finalize } from 'rxjs/operators';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    templateUrl: './welcome.component.html',
    styleUrls: [],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()]
})
export class WelcomeComponent extends AppComponentBase  implements OnInit {

    constructor(injector: Injector,
        private _notificationService: NotificationServiceProxy,
        private _dateTimeService: DateTimeService
    ) 
    {
        super(injector);
    }

    ngOnInit(): void {
        
    }
}
