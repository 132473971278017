<div nz-row nzLayout="horizontal" class="mb-3 mt-3 ora-filter-custom">
    <div nz-col nzSm="{{widthColAdvanced}}">
        <ng-content select="[advancedSearch]"></ng-content>
    </div>
    <ng-content select="[searchMain]"></ng-content>
    <div nz-col class="col-action gutter-row ord-form-control" nzXs="24" nzSm="{{ isOnlySearch ? 24 : widthColSeach }}" >
        <nz-form-label *ngIf="isShowTitleSearch && titleInputSearch">{{ titleInputSearch }}</nz-form-label>
        <div *ngIf="isShowTitleSearch && !titleInputSearch" style="margin-bottom: 30px"></div>
        <div class="searchBoxSpin {{ checkScreen <= 576 ? 'mt-2' : '' }}">
            <ng-content select=".searchBoxOra"></ng-content>
        </div>
    </div>
    <div nz-col nzXs="{{widthColButton}}" class="margin-top-35">
        <button (click)="search()" nz-button nzType="primary" title="{{'TinKiem' | localize}}">
            <i nz-icon nzType="search" nzTheme="outline"></i>{{ 'TinKiem' | localize }}
        </button>
        <button (click)="refresh()" nz-button nzType="default" title="{{'LamMoi' | localize}}">
            <i nz-icon nzType="sync" nzTheme="outline"></i> {{'LamMoi' | localize}}
        </button>
    </div>
    <ng-content select="[actionMain]"></ng-content>

</div>
