import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropDownUploadExcelComponent } from '@app/shared/customize-comp/drop-down-upload-excel/drop-down-upload-excel.component';
import { ImgTempModalComponent } from '@app/shared/customize-comp/img-temp-modal/img-temp-modal.component';
import { ImgTempSelectComponent } from '@app/shared/customize-comp/img-temp-select/img-temp-select.component';
import { DirViewPdfComponent } from '@app/shared/customize-comp/open-view-pdf/dir-view-pdf.component';
import { OraDataPickerComponent } from '@app/shared/customize-comp/ora-data-picker/ora-data-picker.component';
import { OraNumberRollbackComponent } from '@app/shared/customize-comp/ora-number-rollback/ora-number-rollback.component';
import { OraRollbackComponent } from '@app/shared/customize-comp/ora-rollback/ora-rollback.component';
import { OraNumberV2 } from '@app/shared/customize-comp/pipe/ora-number-v2.pipe';
import { SafePipe } from '@app/shared/customize-comp/pipe/SafePipe';
import { XoaTextHuyenPipe } from '@app/shared/customize-comp/pipe/xoa-text-huyen';
import { XoaTextXaPipe } from '@app/shared/customize-comp/pipe/xoa-text-xa';
import { AngularEditorModule } from '@node_modules/@kolkov/angular-editor';
import { CountoModule } from '@node_modules/angular2-counto';
import { NzOutletModule } from '@node_modules/ng-zorro-antd/core/outlet';
import { ColorPickerModule } from '@node_modules/ngx-color-picker';
import { ImageCropperModule } from '@node_modules/ngx-image-cropper';
import { SHARED_ZORRO_MODULES } from '@shared/shared-zorro.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { TextMaskModule } from 'angular2-text-mask';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CircleDataComponent } from './circle-data/circle-data.component';
import { ValidationCustomComponent } from './custom-validate/validation-custom.component';
import { DropDownUploadExcelArrayComponent } from './drop-down-upload-excel/drop-down-upload-excel-array.component';
import { DropzoneFileUploadComponent } from './dropzone-file-upload/dropzone-upload-file-component';
import { FileDinhKemComponent } from './file-dinh-kem/file-dinh-kem.component';
import { OrdGeneralModule } from './general/ord-general.module';
import { OpenViewPdfModalComponent } from './open-view-pdf/open-view-pdf-modal.component';
import { OraCKEditorComponent } from './ora-ckeditor/ora-ckeditor.component';
import { OraColorPickerComponent } from './ora-color-picker/ora-color-picker.component';
import { OraDatePickerV2Component } from './ora-data-picker/ora-date-picker-v2.component ';
import { OraMonthPicker2Component } from './ora-data-picker/ora-month-picker.component';
import { OraEditorComponent } from './ora-editor/ora-editor.component';
import { OraFilterSpinComponent } from './ora-filter-spin/ora-filter-spin.component';
import { OraLayoutFilterComponent } from './ora-layout-filter/ora-layout-filter.component';
import { OraListSwitchComponent } from './ora-list-switch/ora-list-switch.component';
import { OraPageHeaderComponent } from './ora-page-header/ora-page-header.component';
import { OraRangeDatePickerComponent } from './ora-range-date-picker/ora-range-date-picker.component';
import { OraSliderComponent } from './ora-slider/ora-slider.component';
import { OraStepComponent } from './ora-steps/ora-step/ora-step.component';
import { OraStepsComponent } from './ora-steps/ora-steps.component';
import { OraSwitchComponent } from './ora-switch/ora-switch.component';
import { OraTableModule } from './ora-table/ora-table.module';
import { CurrencyInputComponent } from './ord-currency-input/currency-input.component';
import { OrdPhoneNumberInputComponent } from './ord-phone-number/ord-phone-number-input.component';
import { OrdPagesCustomModule } from './pages-custom/ord-pages-custom.module';
import { OrdPwdInputComponent } from './pass-word-custom/ord-pwd-input.component';
import { AbsPipe } from './pipe/abs.pipe';
import { OraNumberStrPipe } from './pipe/ora-numberStrPipe';
import { ReplaceSomeTextPipe } from './pipe/replace-some-text.pipe';
import { StringToObjPipe } from './pipe/string-to-obj.pipe';
import { UppercaseFirstLetterPipe } from './pipe/uppercase-first-letter.pipe';
import { UppercaseFirstWordPipe } from './pipe/uppercase-first-word.pipe';
import { XoaTextTinhPipe } from './pipe/xoa-text-tinh';
import { ProgressBarType2Component } from './progress-bar-type2/progress-bar-type2.component';
import { ProgressBarWithPointComponent } from './progress-bar-with-point/progress-bar-with-point.component';
import { SearchListPageLayoutComponent } from './search-list-page-layout/search-list-page-layout.component';
import { UploadFileNewThemeMultipleComponent } from './upload-file-new-theme/upload-file-new-theme-multiple/upload-file-new-theme-multiple.component';
import { UploadFileNewThemeSingleComponent } from './upload-file-new-theme/upload-file-new-theme-single/upload-file-new-theme-single.component';
import { CustomFileUploadControlComponent } from './upload-file/file-upload-control.component';
import { CustomFileUploadMultiControlComponent } from './upload-file/file-upload-multi-control.component';
import { UploadMultiFileComponent } from './upload-file/modal/upload-multi-file/upload-multi-file.component';
import { ViewPdfKetQuaComponent } from './upload-file/modal/view-pdf/view-pdf.component';
import { CurrencyInput2Component } from './ord-currency-input2/currency-input2.component';



const com = [
    SafePipe,
    OpenViewPdfModalComponent,
    DirViewPdfComponent,
    OraEditorComponent,
    XoaTextXaPipe,
    XoaTextHuyenPipe,
    XoaTextTinhPipe,
    OraNumberStrPipe,
    OraNumberV2,
    DropDownUploadExcelComponent,
    DropDownUploadExcelArrayComponent,
    OraDataPickerComponent,
    ImgTempModalComponent,
    ImgTempSelectComponent,
    CircleDataComponent,
    ProgressBarWithPointComponent,
    OraNumberRollbackComponent,
    OraRollbackComponent,
    ProgressBarType2Component,
    UppercaseFirstWordPipe,
    UppercaseFirstLetterPipe,
    StringToObjPipe,
    AbsPipe,
    OraSliderComponent,
    OraColorPickerComponent,
    ReplaceSomeTextPipe,
    OraStepsComponent,
    OraStepComponent,
    OraSwitchComponent,
    OraListSwitchComponent,
    OraLayoutFilterComponent,
    OraPageHeaderComponent,
    OraFilterSpinComponent,
    ValidationCustomComponent,
    OrdPwdInputComponent,
    FileDinhKemComponent,
    CustomFileUploadControlComponent,
    CustomFileUploadMultiControlComponent,
    UploadMultiFileComponent,
    ViewPdfKetQuaComponent,
    OraCKEditorComponent,
    SearchListPageLayoutComponent,
    OraRangeDatePickerComponent,
    OraDatePickerV2Component,
    OraMonthPicker2Component,
    DropzoneFileUploadComponent,
    UploadFileNewThemeSingleComponent,
    UploadFileNewThemeMultipleComponent,
    OrdPhoneNumberInputComponent,
    CurrencyInputComponent,
    CurrencyInput2Component,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ...SHARED_ZORRO_MODULES,
        AngularEditorModule,
        TextMaskModule,
        ImageCropperModule,
        UtilsModule,
        CountoModule,
        ColorPickerModule,
        NzOutletModule,
        CurrencyMaskModule,

    ],
    declarations: [...com],
    exports: [...com, OraTableModule, OrdGeneralModule, OrdPagesCustomModule],
    providers: [],
})
export class CustomizeCompModule {}
