import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';


@Component({
  selector: 'ord-phone-number-input',
  template: `
    <nz-input-group [nzSuffix]="inputPhoneNumber">
        <input nz-input
        [(ngModel)]="_value"
        type="text"
        [disabled]="disabled"
        (ngModelChange)="changeValue($event)"
        [placeholder]="placeholder" class="w-100" />
    </nz-input-group>
    <ng-template #inputPhoneNumber>
        <i nz-icon class="ant-input-clear-icon" nzTheme="fill" nzType="close-circle" *ngIf="_value" (click)="changeValue(null)"></i>
    </ng-template>`
})
export class OrdPhoneNumberInputComponent implements OnInit {

    @Input() placeholder = '';
    @Input() _value = '';
    @Input() disabled?: boolean = false;
    @Output() _valueChange  =  new EventEmitter();

    ngOnInit(): void {
        
    }

    changeValue($event)
    {
        this._value = $event;
        this._valueChange.emit(this._value);
    }

}
