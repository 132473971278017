import { Component, forwardRef, Input, OnInit, Output, EventEmitter, Provider } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const VALUE_ACCESSOR: Provider = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => CurrencyInputComponent),
	multi: true
};

@Component({
	selector: 'ord-currency-input',
	template: `
    <nz-input-group [nzSuffix]="inputCurrency">
      <input
	  	[ngModel]="_value"
        class="w-100"
        nz-input
        currencyMask
        (ngModelChange)="changeValue($event)"
        class="ord-dynamic-input"
        [disabled]="disabled"
        [placeholder]="placeholder"
        [options]="options"
      />
    </nz-input-group>
    <ng-template #inputCurrency>
        <i nz-icon class="ant-input-clear-icon" nzTheme="fill" nzType="close-circle" *ngIf="_value" (click)="changeValue(null)"></i>
    </ng-template>`,
	providers: [VALUE_ACCESSOR],
})
export class CurrencyInputComponent implements OnInit, ControlValueAccessor {

	@Input() _value = null;
	@Input() placeholder = '';
	@Input() precision?: number = 0;
	@Input() align = 'right';
	@Input() allowZero = true;
	@Input() disabled = null;

	@Output() _valueChange = new EventEmitter();
	options: any = {};

	@Input()
    get value() {
        return this._value;
    }
    set value(v: any) {
        this._value = v;
    }

	private onChange: Function = (v: any) => {
    };

    private onTouched: Function = () => {
		
    };

	ngOnInit(): void {
		var currencySign = ' ' + abp.setting.get("App.MySMSSetting.CurrencyKey") + ' ';
		this.options = {
			suffix: currencySign,
			prefix: '',
			thousands: ',',
			decimal: '.',
			precision: this.precision,
			align: this.align,
			allowZero: this.allowZero
		};
	}

	changeValue($event) {
		this._value = $event;
        this.onChange($event);
		this._valueChange.emit($event);
	}

	writeValue(value: boolean): void {
        this._value = value;
    }

	registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: Function): void {
        this.onTouched = fn;
    }

}
