<button class="kt-margin-r-10" nz-button nz-dropdown nzType="default"
        [nzLoading]="isUploading"
        [nzDropdownMenu]="menuExcel">
    <i class="far fa-file-excel kt-margin-r-5"></i> Thao tác Excel
    <i nz-icon nzType="down"></i>
</button>
<nz-dropdown-menu #menuExcel>
    <ul nz-menu>
        <li nz-menu-item>
            <button nz-button nzType="link" (click)="fileUpload.click()"><i nz-icon
                                                                            nzType="plus"
                                                                            nzTheme="outline"></i>
                {{importText}}
            </button>
            <input type="file" #fileUpload id="fileUpload" name="fileUpload"
                   [formControl]="fileControl"

                   (change)="onFileChange($event)"
                   accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                   style="display:none;"/>
        </li>
        <li nz-menu-item *ngIf="itemMenu1">
            <ng-container *ngTemplateOutlet="itemMenu1"></ng-container>
        </li>
        <li nz-menu-item *ngIf="itemMenu2">
            <ng-container *ngTemplateOutlet="itemMenu2"></ng-container>
        </li>
        <li nz-menu-item *ngIf="itemMenu3">
            <ng-container *ngTemplateOutlet="itemMenu3"></ng-container>
        </li>
        <li nz-menu-divider></li>
        <li nz-menu-item>
            <a [href]="urlMauFile">Tải file dữ liệu mẫu</a>
        </li>
    </ul>
</nz-dropdown-menu>
