<div nz-row>
    <div nz-col="24">
        <h4>{{Title}}</h4>
    </div>
  <div nz-col nzSpan="24" id="formTepDinhKemValidate">
        <nz-table nzTemplateMode>
            <thead>
                <tr>
                    <th nzWidth="60px" class="text-center">
                        STT
                    </th>
                    <th>
                        <span class="font-red">*</span>Tên tài liệu
                    </th>
                    <th nzWidth="150px" class="text-center">
                        <span class="font-red">*</span>Đính kèm
                    </th>
                    <th nzWidth="100px" class="text-center">
                       Thao tác
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let tepDinhKem of listTepDinhKem; let idx = index">
                    <td class="text-center">
                        {{idx + 1}}
                    </td>
                    <td>
                        <input nz-input [(ngModel)]="tepDinhKem.tenTep" [disabled]="isView" placeholder="Nhập ...">
                        <validation-custom [sModel]="tepDinhKem.tenTep"></validation-custom>
                    </td>
                    <td class="text-center">
                        <custom-file-upload-controler [(sModel)]="tepDinhKem.duongDan"
                            (sFileNameChange)="tepDinhKem.tenTep = $event"
                            [isView]="isView" acceptType=".pdf"
                            [urlApi]="urlApi"
                            [uploadUrl]="uploadUrl" [viewUrl]="viewUrl"
                            [downloadUrl]="downloadUrl">
                        </custom-file-upload-controler>
                        <validation-custom [sModel]="tepDinhKem.duongDan"></validation-custom>
                    </td>
                    <td class="text-center">
                        <button nz-button nzType="text" class="font-red"
                        (click)="xoaTepDinhKem(idx)" *ngIf="!isView">
                        <i nz-icon nzType="delete" nzTheme="outline"></i>
                    </button>
                    </td>
                </tr>
                <tr>
                    <td colSpan="4" class="text-right">
                        <button nz-button nzType="primary"
                        (click)="addTepDinhKem()"*ngIf="!isView">
                            <i nz-icon nzType="plus" nzTheme="outline"></i> Thêm dòng
                         </button>
                    </td>
                </tr>
                <tr>
                    <td colSpan="4">
                        <i>Chú ý: Nếu file quá lớn vui lòng chia nhỏ thành từng file, mỗi file tối đa 10 trang. Đính kèm theo số trang theo thứ tự từ trên xuống.</i>
                        <br>
                        <i>Thông tin có dấu <span class="font-red">*</span> cần phải đính kèm</i> <br>
                        <i>Bấm vào <i nz-icon nzType="eye" nzTheme="outline"></i> để xem tệp</i> <br>
                        <i>Bấm vào <i nz-icon nzType="download" nzTheme="outline"></i> để tải tệp xuống</i> <br>
                        <i>Bấm vào <i nz-icon nzType="close" nzTheme="outline"></i> để xóa tệp</i> <br>
                    </td>
                </tr>
            </tbody>
        </nz-table>
  </div>
</div>
<div *nzModalFooter>
    <button type="button" nz-button nzType="default" (click)="close()"><i nz-icon nzType="close"></i>Đóng</button>
    <button type="button" nz-button nzType="primary" (click)="save()" *ngIf="!isView"><i nz-icon nzType="save"></i>Lưu
    </button>
</div>
