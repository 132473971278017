import { Component, Injector, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { NotificationServiceProxy, UserNotification } from '@shared/service-proxies/service-proxies';
import { IFormattedUserNotification, UserNotificationHelper } from './UserNotificationHelper';
import { forEach as _forEach } from 'lodash-es';
import { finalize } from 'rxjs/operators';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { AppConsts } from '@shared/AppConsts';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
    templateUrl: './header-notifications.component.html',
    selector: 'header-notifications',
    styles: [],
    encapsulation: ViewEncapsulation.None
})
export class HeaderNotificationsComponent extends AppComponentBase implements OnInit {

    notifications: IFormattedUserNotification[] = [];
    unreadNotificationCount = 0;
    loading = true;
    private _hubConnection: HubConnection;
    constructor(
        injector: Injector,
        private _notificationService: NotificationServiceProxy,
        private _userNotificationHelper: UserNotificationHelper,
        public _zone: NgZone,
        private _nzNotification: NzNotificationService
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.loadNotifications();
        this.registerToEvents();
    }

    loadNotifications(): void {
        this._notificationService.getUserNotifications(undefined, undefined, undefined, 3, 0)
            .pipe(finalize(() => this.loading = false))
            .subscribe(result => {
                this.unreadNotificationCount = result.unreadCount;
                this.notifications = [];
                _forEach(result.items, (item: UserNotification) => {
                    this.notifications.push(this._userNotificationHelper.format(<any>item));
                });
            });
    }


    subcribleSignalR() {
        this._hubConnection = new HubConnectionBuilder().withUrl(`${AppConsts.remoteServiceBaseUrl}/notify`).build();
        this._hubConnection.start()
            .then(() => {
                console.log('signalR connected');
            })
            .catch(err => {
                console.error('signalR error: ', err);
            });
        this._hubConnection.on('BroadcastMessage', (message) => {
            this.loadNotifications();
        });
    }

    registerToEvents() {
        let self = this;
        this.subcribleSignalR();
        function onNotificationReceived(userNotification) {
            self._userNotificationHelper.show(userNotification);
            self.loadNotifications();
        }

        abp.event.on('app.notifications.received', userNotification => {
            self._zone.run(() => {
                onNotificationReceived(userNotification);
            });
        });

        function onNotificationsRefresh() {
            self.loadNotifications();
        }

        abp.event.on('app.notifications.refresh', () => {
            self._zone.run(() => {
                onNotificationsRefresh();
            });
        });

        function onNotificationsRead(userNotificationId) {
            for (let i = 0; i < self.notifications.length; i++) {
                if (self.notifications[i].userNotificationId === userNotificationId) {
                    self.notifications[i].state = 'READ';
                    self.notifications[i].isUnread = false;
                }
            }
            self.unreadNotificationCount -= 1;
        }

        abp.event.on('app.notifications.read', userNotificationId => {
            self._zone.run(() => {
                onNotificationsRead(userNotificationId);
            });
        });

    }

    setAllNotificationsAsRead(): void {
        this._userNotificationHelper.setAllAsRead();
    }

    openNotificationSettingsModal(): void {
        this._userNotificationHelper.openSettingsModal();
    }

    setNotificationAsRead(userNotification: IFormattedUserNotification): void {
        if (userNotification.state !== 'READ') {
            this._userNotificationHelper.setAsRead(userNotification.userNotificationId);
        }
    }

    gotoUrl(item): void {
        setTimeout(()=>{                           
            if (item.url) {
                location.href = item.url;
            }
        }, 500);
        
    }
}
