import { AppConsts } from "./AppConsts";

export class AppCommonTableCode {
    //table
    static Tinh = 'Tinh';


    //enum
    static GIOI_TINH = 'GIOI_TINH';


}

export class AppViewConst {

    static UserUploadUrl =  AppConsts.remoteServiceBaseUrl + "/api/FileUpload/UploadFileUser";
    static UserViewDecryptUrl = AppConsts.remoteServiceBaseUrl + "/api/FileUpload/GoToViewUserUploadDecrypt";
    static UserViewUrl = AppConsts.remoteServiceBaseUrl + "/api/FileUpload/GoToViewUserUpload";
    static UserDownloadUrl = AppConsts.remoteServiceBaseUrl + "/api/FileUpload/DownloadFileUser";
    static TempDownloadByToken = AppConsts.remoteServiceBaseUrl + "/api/FileUpload/DownloadTempFileByToken?token=";
    static TempDownloadByFileName = AppConsts.remoteServiceBaseUrl + "/api/FileUpload/DownloadFileTempByName?fileName=";

    static init(){
        AppViewConst.UserUploadUrl =  AppConsts.remoteServiceBaseUrl + "/api/FileUpload/UploadFileUser";
        AppViewConst.UserViewDecryptUrl = AppConsts.remoteServiceBaseUrl + "/api/FileUpload/GoToViewUserUploadDecrypt";
        AppViewConst.UserViewUrl = AppConsts.remoteServiceBaseUrl + "/api/FileUpload/GoToViewUserUpload";
        AppViewConst.UserDownloadUrl = AppConsts.remoteServiceBaseUrl + "/api/FileUpload/DownloadFileUser";
        AppViewConst.TempDownloadByToken = AppConsts.remoteServiceBaseUrl + "/api/FileUpload/DownloadTempFileByToken?token=";
        AppViewConst.TempDownloadByFileName = AppConsts.remoteServiceBaseUrl + "/api/FileUpload/DownloadFileTempByName?fileName=";
    }


}
