<div class="content content-form d-flex flex-column flex-column-fluid">
    <div class="root">
        <div class="title">
            <nz-breadcrumb class="bold" nzSeparator=">">
                <i nz-icon nzType="unordered-list" nzTheme="outline"></i> &nbsp;
                <nz-breadcrumb-item>
                    <span>{{'Notifications' | localize}}</span>
                </nz-breadcrumb-item>
            </nz-breadcrumb>
        </div>
        <div class="action">
            <nz-form-control>
                <button nz-button [nzType]="'primary'" (click)="setAllNotificationsAsRead()">
                    <i nz-icon nzType="check"></i>
                    <span>
                        {{"SetAllAsRead" | localize}}
                    </span>
                </button>
            </nz-form-control>

        </div>
    </div>
    <ng-container>
        <div nz-form [nzLayout]="'vertical'" class="mt-3 mb-3 ora-filter-custom">
            <nz-row nzGutter="8">
                <nz-col nzSm="12">
                    <nz-form-item>
                        <nz-form-control>
                            <nz-row>
                                <nz-col nzSpan="12">
                                    <ora-date-picker [(ngModel)]="filter.fromDate" (ngModelChange)="onSearch()"
                                        placeHolder="{{ 'TuNgay' | localize }}"></ora-date-picker>
                                </nz-col>
                                <nz-col nzSpan="12">
                                    <ora-date-picker [(ngModel)]="filter.toDate" (ngModelChange)="onSearch()"
                                        placeHolder="{{ 'DenNgay' | localize }}"></ora-date-picker>
                                </nz-col>
                                </nz-row>

                        </nz-form-control>
                    </nz-form-item>
                </nz-col>
                <nz-col nzSm="6">
                    <nz-form-item>
                        <nz-form-control>
                            <nz-select style="width: 100%;" [(ngModel)]="readStateFilter" (ngModelChange)="onSearch()"
                                name="ReadStateFilter">
                                <nz-option nzValue="ALL" [nzLabel]="l('All')"></nz-option>
                                <nz-option nzValue="UNREAD" [nzLabel]="l('Unread')"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </nz-col>

            </nz-row>
        </div>

        <div class="fix-table">
            <div class="title-table">
                <span>{{'Layout_Danhsach' | localize}}</span>
            </div>
            <nz-table #ajaxTable [nzData]="dataList" [nzTotal]="totalItems" [(nzPageIndex)]="pageNumber"
            [(nzPageSize)]="pageSize" [nzLoading]="isTableLoading" (nzPageIndexChange)="pageNumberChange()"
            (nzPageSizeChange)="refresh()" [nzShowSizeChanger]="true" [nzShowQuickJumper]="true"
            [nzShowTotal]="totalTemplate" [nzFrontPagination]="false">
            <ng-template #totalTemplate let-total>
                {{'TotalRecordsCount' | localize:total}}
            </ng-template>
            <thead (nzSortOrderChange)="gridSort($event)">
                <tr>
                    <th>
                        {{'Notification' | localize}}
                    </th>
                    <th>
                        {{'CreationTime' | localize}}
                    </th>
                    <th nzWidth="200px" class="text-center">
                        {{'Actions' | localize}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of ajaxTable.data">
                    <td>
                        <a *ngIf="item.formattedNotification.url"
                            href="{{item.formattedNotification.url}}">{{truncateString(item.formattedNotification.text,
                            120)}}</a>
                        <span *ngIf="!item.formattedNotification.url"
                            title="{{item.formattedNotification.text}}">{{truncateString(item.formattedNotification.text,
                            120)}}</span>
                    </td>
                    <td>
                        <span nz-tooltip [nzTooltipTitle]="item.notification.creationTime | luxonFormat:'ffff'">
                            {{ item.notification.creationTime | luxonFromNow }}
                        </span>
                    </td>

                    <td class="text-center">
                        <ng-container *ngIf="!isRead(item)">
                            <a (click)="setAsRead(item)">
                                <i nz-icon nzType="check"></i>
                                {{'SetAsRead' | localize}}
                            </a>
                            <nz-divider nzType="vertical"></nz-divider>
                        </ng-container>
                        <ng-container>
                            <a nz-popconfirm [nzPopconfirmTitle]="l('NotificationDeleteWarningMessage')"
                                (nzOnConfirm)="deleteNotification(item)" [nzOkText]="l('Ok')"
                                [nzCancelText]="l('Cancel')">
                                <i nz-icon nzType="delete"></i>
                                {{'Delete' | localize}}
                            </a>
                        </ng-container>
                    </td>
                </tr>
            </tbody>
        </nz-table>
        </div>
        <!-- <nz-row class="my-md">

        </nz-row> -->
    </ng-container>
</div>




