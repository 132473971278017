import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, Injector, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from '@delon/theme';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Subscription } from 'rxjs';

@Component({
    selector: 'layout-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.less'],
    encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent extends AppComponentBase implements OnInit{

    private notify$: Subscription;

    constructor(
        injector: Injector,
        public settingsSrv: SettingsService,
        private el: ElementRef,
        private settings: SettingsService,
        private _router: Router,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private doc: any
    ){
            super(injector);
    }
    
    ngOnInit(): void {
        this.getCountMessage();
        this.notify$ = this.settings.notify.subscribe(() => this.setClass());
        this.setClass();
    }

    
    get collapsed() {
        return this.settingsSrv.layout.collapsed;
    }

    toggleCollapsed() {
        this.settingsSrv.setLayout('collapsed', !this.collapsed);
    }

    getCountMessage(){
        if(this.appSession.tenant)
        {
            this.appSession.getCountSMS();
        }
    }

    gotoUrl(url)
    {
        this._router.navigate([url]);
    }

    private setClass() {
        const { el, doc, renderer, settings } = this;
        const layout = settings.layout;
    }

}
