import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
@Component({
  selector: 'ora-filter-spin',
  templateUrl: './ora-filter-spin.component.html',
  styleUrls: ['./ora-filter-spin.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OraFilterSpinComponent implements OnInit {
  @Input() searchAfterEnter = true;
  @Input() hasAdvancedSearch = false;
  @Input() isOnlySearch? = false;
  @Input() isShowTitleSearch? = false;
  @Input() titleInputSearch? = '';
  @Input() widthColSeach? = 12;
  @Input() widthColButton? = 6;
  @Input() widthColAdvanced? = 24;
  @Output() onSearch = new EventEmitter();
  @Output() onRefresh = new EventEmitter();
  showAdvancedSearch= false;
  checkScreen = window.innerWidth;
  constructor() {}

  ngOnInit(): void {}

  search() {
    this.onSearch.emit();
  }
  refresh() {
    this.onRefresh.emit();
  }

  onEnter() {
    if (this.searchAfterEnter) {
      this.search();
    }
  }


  clickShowHideAdvancedSearch() {
    this.showAdvancedSearch = !this.showAdvancedSearch;
  }


}
