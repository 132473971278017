<div>
  <div class="modal-header">
    <div class="modal-title">
      {{'LoginAttempts' | localize}}
    </div>
  </div>
  <nz-card *ngFor="let item of userLoginAttempts" style="margin-top: 15px;">
    <div nz-row [nzGutter]="8">
      <div nz-col [nzSpan]="4">
        <label>{{'LoginState' | localize}}</label>
      </div>

      <div nz-col [nzSpan]="20">
        <nz-badge *ngIf="item.result == 'Success'" [nzStatus]="'success'" [nzText]="l('Success')"></nz-badge>
        <nz-badge *ngIf="item.result != 'Success'" [nzStatus]="'error'" [nzText]="l('Failed')"></nz-badge>
      </div>
    </div>
    <div nz-row [nzGutter]="8">
      <div nz-col [nzSpan]="4">
        <label>{{'IpAddress' | localize}}:</label>
      </div>

      <div nz-col [nzSpan]="20">
        {{item.clientIpAddress}}
      </div>
    </div>

    <div nz-row [nzGutter]="8">
      <div nz-col [nzSpan]="4">
        <label>{{'Client' | localize}}:</label>
      </div>

      <div nz-col [nzSpan]="20">
        {{item.clientName}}
      </div>
    </div>

    <div nz-row [nzGutter]="8">
      <div nz-col [nzSpan]="4">
        <label>{{'Browser' | localize}}:</label>
      </div>

      <div nz-col [nzSpan]="20">
        {{item.browserInfo}}
      </div>
    </div>

    <div nz-row [nzGutter]="8">
      <div nz-col [nzSpan]="4">
        <label>{{'Time' | localize}}:</label>
      </div>

      <div nz-col [nzSpan]="20">
        {{getLoginAttemptTime(item)}}
      </div>
    </div>
  </nz-card>


</div>
