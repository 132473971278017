import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AppUtilityService } from '@app/shared/common/custom/utility.service';
import { takeUntil } from '@node_modules/rxjs/internal/operators';
import { DestroyRxjsService } from '@shared/destroy-rxjs.service';
import * as _ from 'lodash';

import { SearchListPageLayoutStateService } from './services/search-list-page-layout-state.service';
@Component({
  selector: 'ord-search-list-page-layout',
  templateUrl: './search-list-page-layout.component.html',
  styleUrls: ['./search-list-page-layout.component.scss'],
  providers: [DestroyRxjsService]
})
export class SearchListPageLayoutComponent implements OnInit, OnChanges, OnDestroy {
  @Input() title = '';
  @Input() contentTable = '';
  @Input() totalCount = 0;
  @Input() isHasActionControl = true;
  @Input() isTableHasSelectColBtn = false;
  //@Input() hotKeyRef: IHotKeyRef;
  //private tabSelected: TabMenuModel = null;
  //private listOfHostKeyAdded: Hotkey[] = [];

  constructor(//private hotkeysService: HotkeysService,
    //private tabState: TabMenuStateService,
    private readonly destroyService: DestroyRxjsService) {
  }

  ngOnInit(): void {
    // this.tabState.tabSelected$.pipe(takeUntil(this.destroyService))
    //   .subscribe(tab => {
    //     this.tabSelected = tab;
    //     setTimeout(() => {
    //       this.initHotKey(tab);
    //     });
    //   });
  }

  ngOnChanges(changes: SimpleChanges): void {

  }


  initHotKey(tabSelected) {
    // if (AppUtilityService.isNullOrEmpty(tabSelected) || AppUtilityService.isNullOrEmpty(this.hotKeyRef)) {
    //   return;
    // }
    // const isInTabSelected = tabSelected.path.indexOf(this.hotKeyRef.tabName) > -1;
    // if (isInTabSelected === true) {
    //   this.hotKeyRef.hotKeys.forEach(hk => {
    //     const hotKey = new Hotkey(hk.name, (event: KeyboardEvent) => {
    //       if (this.tabSelected.path.indexOf(this.hotKeyRef.tabName) > -1) {
    //         hk.executeFunc();
    //         return false;
    //       }
    //       return true;
    //     }, ['INPUT', 'TEXTAREA', 'SELECT']);
    //     this.listOfHostKeyAdded.push(hotKey);
    //     //this.hotkeysService.add(hotKey);
    //   });
    // }
  }

  ngOnDestroy(): void {
    // if (AppUtilityService.isNotAnyItem(this.listOfHostKeyAdded) === false) {
    //   this.listOfHostKeyAdded.forEach(it => {
    //     this.hotkeysService.remove(it);
    //   });
    // }
  }

}
