import { AfterViewInit, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalHelper } from '@delon/theme';
import * as _ from 'lodash';

import { NzUploadFile } from 'ng-zorro-antd/upload';
import { v4 as uuid } from 'uuid';
import { UploadMultiFileComponent } from './modal/upload-multi-file/upload-multi-file.component';
import { ViewPdfKetQuaComponent } from './modal/view-pdf/view-pdf.component';

const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

@Component({
  selector: 'custom-file-upload-multi-controler',
  template: `
    <span style="display: inline-grid;">
      <button nz-button (click)="openUpload()" *ngIf="!isView"><i nz-icon nzType="upload" ></i><span>Tải tệp</span></button>
      <button nz-button (click)="openUpload()" *ngIf="isView"><i nz-icon nzType="eye" ></i><span>Xem</span></button>
      <div *ngIf="fileUploadCount > 0">
        <span>
            <i>(Đã tải lên {{fileUploadCount}} file) </i>
        </span>
      </div>
    </span>

  `,
   providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomFileUploadMultiControlComponent),
    multi: true
  }]
})
export class CustomFileUploadMultiControlComponent implements AfterViewInit {
  @Input() Title = "";
  @Input() isView = false;
  @Input() sLimit? = 1;
  @Input() urlApi = '';
  @Input() sModel: any[] = [];
  @Input() sFileName: '';
  @Input() sType: '';
  @Input() uploadUrl: string = '';
  @Input() viewUrl: string = '';
  @Input() downloadUrl: string = '';
  @Input()fileUploadCount = 0;
  @Input() acceptType: string = '.pdf,.docx,.doc,.xlsx,.xls';
  @Output() sModelChange = new EventEmitter();
  @Output() fileUploadCountChange = new EventEmitter();
  constructor(private modalHelper: ModalHelper) {}

  ngAfterViewInit() {
      this.fileUploadCount = this.sModel.filter(p=>p.duongDan).length;
      this.fileUploadCountChange.emit(this.fileUploadCount);
  }

   openUpload(){
    let icon = ' <span id="full-screen" class="fas fa-expand full-screen"></span>';

    this.modalHelper
    .create(
        UploadMultiFileComponent, {
            listTepDinhKem: _.cloneDeep(this.sModel),
            Title: this.Title,
            uploadUrl : this.uploadUrl,
            viewUrl : this.viewUrl,
            downloadUrl : this.downloadUrl,
            isView: this.isView,
        }, {
            size: 1300,
            includeTabs: false,
            modalOptions: {
                nzTitle: 'Đính kèm tài liệu' + icon,
            },
        },
    )
    .subscribe((result) => {
        if (result) {
            this.sModel = result;
            this.sModelChange.emit(this.sModel);
            this.fileUploadCount = this.sModel.filter(p=>p.duongDan).length;
            this.fileUploadCountChange.emit(this.fileUploadCount);
        }
    });
   }

}
