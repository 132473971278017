
<div class="content content-form d-flex flex-column flex-column-fluid">

    <ng-container>
        <div class="mt-3">
           <h3 class="text-center text-uppercase">{{'ChaoMungBanDaDenVoiHeThongMessageHub' | localize}}</h3>
           <div class="text-center">{{'XinVuiLongChonMenuBenTraiDeTiepTuc' | localize}}</div>
        </div>
    </ng-container>
</div>
