<!-- <nz-card>
  <ng-content select="filter-box"></ng-content>
  <ng-content select="[filterBox]"></ng-content>
  <h5 class="margin-top-10">{{tableListTitle}}</h5>
  <ng-content select="table-list"></ng-content>
  <ng-content select="[tableList]"></ng-content>
  <ng-content select="[footer]"></ng-content>
</nz-card> -->

<ng-content select="[pageHeader]"></ng-content>
<ng-content select="[filterBox]"></ng-content>
<label class="font-weight-bold font-italic" *ngIf="contentTable != ''">{{contentTable}} ({{totalCount | oraNumber:'1.0-0':'en'}} bản ghi)</label>
<ng-content select="[tableList]"></ng-content>


