import { AfterViewInit, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalHelper } from '@delon/theme';
import { AppViewConst } from '@shared/AppViewConst';

import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, Observer } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { ViewPdfKetQuaComponent } from './modal/view-pdf/view-pdf.component';

const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

@Component({
  selector: 'custom-file-upload-controler',
  template: `
    <span style="display: inline-block;">
      <nz-upload
        [nzAccept]="acceptType"
        [nzMultiple]="false"
        [nzBeforeUpload]="beforeUpload"
        (nzChange)="uploadTepDinhKem($event)"
        *ngIf="!sModel && !isView"
        [nzAction]="uploadUrl"
      >
        <button nz-button><i nz-icon nzType="upload"></i><span>Upload</span></button>
      </nz-upload>

      <a
        *ngIf="sModel"
        nz-button
        nzSize="small"
        nzType="link"
        style="margin-right: 5px !important;"
        (click)="viewTepDinhKem(sModel)"
      >
        <i nz-icon nzType="eye" nzTheme="outline"></i>
      </a>

      <a
        *ngIf="sModel"
        nz-button
        nzSize="small"
        nzType="link"
        style="margin-right: 5px !important;"
        (click)="taiTepDinhKem(sModel)"
      >
      <i nz-icon nzType="download" nzTheme="outline"></i>
      </a>

      <a
        *ngIf="sModel && !isView"
        nz-button
        nzSize="small"
        nzType="link"
        style="margin-right: 5px !important;"
        (click)="xoaDuongDanTepDinhKem()"
      >
        <i nz-icon nzType="close" nzTheme="outline"></i>
      </a>
</span>
  `,
   providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomFileUploadControlComponent),
    multi: true
  }]
})
export class CustomFileUploadControlComponent implements AfterViewInit {
  @Input() isView = false;
  @Input() sLimit? = 15;
  @Input() urlApi = '';
  @Input() sModel: '';
  @Input() sFileName: '';
  @Input() sType: '';
  @Input() uploadUrl: string =  AppViewConst.UserUploadUrl;
  @Input() viewUrl: string = AppViewConst.UserViewUrl;
  @Input() downloadUrl: string =  AppViewConst.UserDownloadUrl;
  @Input() acceptType: string = '.pdf,.docx,.doc,.xlsx,.xls';
  @Output() sModelChange = new EventEmitter();
  @Output() sFileNameChange = new EventEmitter();
  @Output() sTypeChange = new EventEmitter();
  @Output() eventChange = new EventEmitter();

  constructor(private modalHelper: ModalHelper) {}

  ngAfterViewInit() {}

  beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]): Observable<boolean> =>
  new Observable((observer: Observer<boolean>) => {
    var indexOfPoint = file.name.lastIndexOf(".");
    var fileType = file.name.substring(indexOfPoint, file.name.length);
    const acceptTypeCheck = this.acceptType.includes(fileType);
    if (!acceptTypeCheck) {
      abp.notify.error("File upload phải đúng định dạng " + this.acceptType)
      observer.complete();
      return;
    }
    const mb = file.size! / 1024 / 1024;
    const isCheckSize = mb < this.sLimit;
    if (!isCheckSize) {
        abp.notify.error("File upload không được quá " + this.sLimit + "MB")
      observer.complete();
      return;
    }
    observer.next(acceptTypeCheck && isCheckSize);
    observer.complete();
  });

  uploadTepDinhKem(info: any): void {
    // 2. read from response and show file link
    if (info.file.status == 'error') {
      info.file.message = info.file.error.error.message;
    }

    if (info.file.response && info.file.response.success == true) {
      var result = info.file.response.result;
      this.sFileName = result.lstDataResult[0].fileName;
      this.sModel = result.lstDataResult[0].path;
      this.sType = result.lstDataResult[0].type;
      this.sModelChange.emit(this.sModel);
      this.sTypeChange.emit(this.sType);
      this.sFileNameChange.emit(this.sFileName);
    }
  }

  viewTepDinhKem(url) {
    let path = "";
    if(this.viewUrl != '') {
      path= this.viewUrl+"?url="+url;
    }
    this.modalHelper
      .create(
        ViewPdfKetQuaComponent,
        {
          path: path,
        },
        {
          size: 1300,
          includeTabs: false,
          modalOptions: {
            nzTitle: 'View document',
          },
        },
      )
      .subscribe((result) => {
        if (result) {
        }
      });
    //  const win = window.open(path, '', 'height=800,width=840');
  }

  taiTepDinhKem(url) {
    let path = "";
    if(this.downloadUrl != '')
    {
      path = this.downloadUrl+"?url="+url;
    }
    location.href = path;
  }

  xoaDuongDanTepDinhKem() {
    this.sModel = '';
    this.sModelChange.emit(this.sModel);
  }
}
